


export default function OrderError(){
    return (
        <div className="order-success order-final-msg">
            <h1> Ой! Сталася помилка! </h1>
            <p> Спробуйте ще раз або зверніться до нас по телефону: +380 (067) 789-89-89 </p>
        </div>
    )
}