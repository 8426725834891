import PartnersBlock from "../../Components/MainSite/Partners/PartnersBlock";




export default function Partners() {
    return(
        <>
            <PartnersBlock/>
        </>
    )
}