const MainTranslate = {
    ua: {
        header: {
            retail: "Роздріб",
            wholesale: "Опт",
            about: "Про нас",
            contacts: "Контакти",
            projects: "Проекти",
            achievements: "Досягнення",
            knowledges: "База знань",
            documents: "Загальна інформація",
        },
        msgs: {
            notFound: "",
            inDevelop: "Сторінка знаходиться в розробці",
            sorry: "ВИБАЧТЕ ЗА ТИМЧАСОВІ НЕЗРУЧНОСТІ",
        },
        MainPage: {
            intro: {
                title: "Сучасні технології розмноження та дорощування саджанців",
                btnRetail: "Роздріб",
                btnWholesale: "Опт",
            },
            info: {
                headLeft:
                    "Сучасні технології размножения і вирощування саджанців",
                leftParagraph: [
                    "Брусвяна - розсадник ягідних культур з понад 15-річним досвідом успішної роботи на ринку України та за її межами. ",
                    "Заснований у 2005 році. Одними з перших почали розвивати популярну нині культуру - лохину високорослу.",
                    "Надаємо комплексну підтримку клієнту для забезпечення гарного результату, від вибору культури та сорту до агротехнічних заходів.",
                    "Основними культурами виробництва є лохина високоросла, малина ремонтантна, суниця альпійська.",
                    "Унікальна особливість розсадника - власна селекція: 6 сортів ремонтантної малини та 1 сорт лохини користуються популярністю та занесені до реєстру сортів України.",
                    "Брусвяна є надійним партнером, що пропонує індивідуальні рішення для кожної групи клієнтів. Ми працюємо з великими та дрібними фермерськими господарствами, садовими центрами, торговими мережами, дрібними оптовими клієнтами.",
                    "Співробітники компанії від виробництва до відділу збуту піклуються про якість та швидкість виконання ваших замовлень.",
                ],
                headRight:
                    "Вже понад 16 років якість, сортність та сервіс – наша запорука успіху!",
            },
            infoBlocks: {
                block1: {
                    title: "Рослин  вирощено в рік",
                    text: "1 200 000",
                },
                block2: {
                    title: "Сортів в колекції",
                    text: "200",
                },
                block3: {
                    title: "Гектарів",
                    text: "1 000",
                },
            },
            bigBtns: {
                btnRetail: "Роздрібні пропозиції від Брусвяна",
                btnWholesale: "Оптові пропозиції від Брусвяна",
                click: "Переглянути ",
            },
            partners: {
                head: "Партнери",
                text: [
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                ],
            },
        },
        About: {
            aboutText: {
                head: "Про нас",
                miniHead: "Про компанію",
                text1: "«Брусвяна» - українська компанія заснована родиною Дмитрієвих у 2005 році в селі Костівці.  Основний напрям – виробництво саджанців ягідних культур: лохина, малина, суниця альпійська, обліпиха. Сьогодні «Брусвяна» має значний досвід у галузі рослинництва, є одним з лідерів у своїй справі, має авторські ліцензії на власні сорти. Бере активну участь у заходах щодо покращення екосистеми планети.",
                minihead2: "Селекція",
                text2: "За 15 років існування фахівцями компанії було створено 6 сортів ремонтантної малини. До них належать Брусвяна, Брусилівська, Брусилівський стандарт, Примара, Ремарка та Ярославна. Брусвяна та Брусилівська були відзначені нагородами. Представлені сорти малини вирощувались в багатьох регіонах України і зарекомендували себе надійними для вирощуванні у відкритому грунті.",
                text22: "У 2008 році було створено перший вітчизняний сорт лохини – Фіолент, який був занесений до реєстру сортів та користується популярністю серед садівників та фермерів як сорт придатний до механізованого збору та переробки.",
                minihead3: "Історія",
                text31: "У 2005 році в селі Костівці на площі 0,45 га було створено розсадник та садовий центр Брусвяна. Активно розмножувався широкий спектр рослин – як ягідні, плодові, так і декоративні рослини. У 2007 – 2009 роках з’явився запит на промислові ягідники. Як наслідок, розсадник збільшив площі та отримав вужчу спеціалізацію – ягідні культури.",
                text32: "Сьогодні Брусвяна це сучасний комплекс розмноження та адаптації рослин, 4,5 га контейнерних площадок , 1 га теплиць, селекційний відділ. Починаючи з 2005 року потужності нашого виробництва виросли з 20 тис.  до 1,5 млн рослин в рік.",
                text33: "Компанія займається просвітницькою діяльністю організовуючи навчальні семінари, конференції, форуми та інші масові заходи. Від так починаючи з 2013 року «Брусвяна» є організатором науково-практичної конференції «Лохина високоросла та інші ягідні культури», яка згодом трансформувалась у ягідний форум. Учасниками заходу були бізнесмени, науковці, дрібні та великі фермери. Для садівників-любителів був організований «Ягідний Фестиваль Брусвяна-Україна».",
                text34: "Наша місія – поліпшення довкілля та підвищення добробуту суспільства.",
                text35: "Мета - створення, поліпшення, вирощування та насадження рослин на планеті.",
            },
            team: {
                head: "Команда",
                team: [
                    {
                        name: "Дмитрієва Ліліана Вячеславівна",
                        img: "1.jpg",
                        position: "Власниця групи компанії «Брусвяна»",
                        description: "Контакти: +380674102477",
                    },
                    {
                        name: "Євпак Катерина Євгенівна",
                        img: "2.jpg",
                        position: "Зам. директора з виробництва",
                        description:
                            "Контакти: brusvyana@ukr.net +380674113695",
                    },
                    {
                        name: "Король Євген Антонійович",
                        img: "3.jpg",
                        position: "Директор садового центра Брусвяна",
                        description:
                            "Контакти: brusvyana@ukr.net +380674113692",
                    },
                    // {
                    //     name: "Горова Тетяна Сергіївна",
                    //     img: "4.jpg",
                    //     position: "Менеджер по роботі з оптовими клієнтами",
                    //     description:
                    //         "Контакти: brusvyana@ukr.net +380674113691",
                    // },
                ],
            },
        },
        Documents: {
            pay: {
                head: "Оплата",
                list: [
                    "Оплата на карту ПриватБанку через додаток Приват24 або через термінали поповнення ПриватБанку, IBox. Зберігайте чек після оплати до моменту підтвердження зарахування коштів.",
                    'Оплата накладеного платежу у відділеннях служб доставки "Нова Пошта", після отримання замовлення та його перевірки.',
                    "Оплата через сервіс LiqPay, на ваш email буде надіслано інвойс та реквізити для оплати.",
                ],
            },
            delivery: {
                head: "Доставка",
                text: "",
                list: [
                    "Самовивіз з садового центру «Брусвяна»",
                    "Доставка у відділення «Нової пошти»",
                    "Адресна доставка «Нова пошта»",
                    "Доставка великогабаритного товару машиною перевізника (вартість попередньо уточнюється в залежності від регіону доставки).",
                ],
            },
            refund: {
                head: "Гарантія та повернення",
                text: "Товар придбаний у садовому центрі «Брусвяна», поверненню і обміну не підлягає за винятком наступних моментів:",
                list: [
                    "Гарантія на рослини діє за умови, що покупцем садового центра було оформлено послугу з висадки рослин працівниками підприємства. А також за наявності автоматичного або крапельного зрошення на Вашій ділянці. В даному випадку ми безкоштовно проведемо заміну рослини, в разі її загибелі, протягом 1 (одного) календарного року після висадки.",
                    "Ви придбали плодові дерева в період березень-травень, але саджанець «не прокинувся». Протягом 2-х місяців від дати покупки рослини Ви можете зробити обмін. При собі обов`язково мати товарний чек, в якому вказано найменування саджанця та контейнер із штрихкодом.",
                ],
            },
            policy: {
                head: "Угода користувача",
                block1: {
                    head: "ЗАГАЛЬНІ ПОЛОЖЕННЯ",
                    list: [
                        "Відповідно до статті 633 Цивільного кодексу України цей договір є офіційною пропозицією Сторони 1 - ФІЗИЧНА ОСОБА ПІДПРИЄМЕЦЬ ДМИТРІЄВА ЛІЛІАНА ВЯЧЕСЛАВІВНА через Інтернет-магазин  http://brusvyana.com.ua/  (далі - Продавець) укласти договір купівлі-продажу товарів та/або договору про надання послуг на викладених у цій Оферті умовах.",
                        "Дана пропозиція є публічним договором-офертою (далі Договір) і має відповідну юридичну силу, про продаж необмеженому колу осіб (далі Покупець) товарів, та надання необмеженому колу осіб Послуг, які представленні у відповідному розділі Веб-сайту Продавця.",
                        "Положення даного Договору регулюють взаємовідносини між Продавцем з одного боку і Покупцем з іншого боку (при спільному згадуванні іменовані - Сторони) на підставі умов даного Договору.",
                        "Всі користувачі Веб-сайту Продавця можуть отримати доступ до Договору (оферти) в будь-який момент, натиснувши на посилання «Публічна оферта» на Сайті Продавця.",
                        "Цим Договором Сторони підтверджують досягнення повної згоди між ними щодо правил, термінів, обмежень та інших умов співпраці, викладених в даному Договорі.",
                        "Даний договір з моменту його укладення припиняє гарантії, пакти і будь-які інші домовленості (в письмовій та усній формі) між Сторонами і має обов`язкову силу для Сторін.",
                        "Укладаючи Договір (тобто акцептуючи умови справжньої Пропозиції (шляхом оформлення Замовлення), Покупець підтверджує наступне:",
                        [
                            "Покупець цілком і повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);",
                            "Надана Покупцем інформація під час оформлення замовлення Товарів або Послуг є правильною та актуальною;",
                            "Покупець дає дозвіл на збір, обробку та передачу персональних даних з метою можливості виконання умов цього Договору, можливості проведення взаєморозрахунків, а також, для отримання рахунків, актів та інших документів. Дозвіл на обробку персональних даних діє протягом усього строку дії Договору.",
                        ],
                    ],
                },
                block2: {
                    head: "ВИЗНАЧЕННЯ І ТЕРМІНИ.",
                    list: [
                        "У цій оферті, якщо контекст не вимагає іншого, наведені нижче терміни мають таке значення:",
                        [
                            "«Оферта» - публічна пропозиція Продавця, адресована будь-якій фізичній та юридичній особі, з метою укласти договір купівлі-продажу Товару, та/або договору надання послуг на існуючих умовах, зазначених в Договорі.",
                            "«Товар» - перелік найменувань асортименту, щодо придбання якого на Веб-сайті http://brusvyana.com.ua/  розміщена пропозиція Продавця, але не обмежуючись тільки сайтом.",
                            "«Послуги» - перелік дій, які вчиняються Продавцем за рахунок Покупця з переліку послуг, які доступні до замовлення Покупцем на Веб-сайті http://brusvyana.com.ua/ .",
                            "«Продавець» - компанія, яка  є власником чи розповсюджувачем Товару та реалізує такий Товар та надає відповідні Послуги за допомогою Веб-сайту http://brusvyana.com.ua/ .",
                            "«Покупець» - фізична, або юридична особа, що вступили у договірні відносини із Продавцем на умовах, зазначених у даному Договорі.",
                            "«Акцепт» - повне та беззаперечне прийняття Покупцем умов Договору.",
                            "«Веб-сайт» - автоматизована інформаційна система, доступна в мережі Інтернет за мережевою адресою: http://brusvyana.com.ua/ , а також всі «піддомени», які відносяться до даної адреси та використовуються Продавцем на праві власності.",
                            "«Замовлення» – належно оформлена та розміщена на Веб-сайті Продавця заявка Покупця на купівлю Товарів та/або замовлення Послуг, що адресується Продавцю.",
                            "«Кур’єрська доставка» - безпосередня передача Товару від працівника служби доставки, або від працівника Продавця, або від іншої уповноваженої Продавцем особи Покупцю в місці, вказаному Покупцем як адреса доставки, на платній або безоплатній умовах.",
                            "«Платіжна система» – банк/учасник платіжної системи/оператор послуг платіжної інфраструктури, що на договірній основі з Продавцем забезпечує приймання та переказ платежів з використанням реквізитів платіжних карток Покупців за Товари та/або Послуги, запропоновані на Веб-сайті Продавця.",
                            "Особистий кабінет – інформаційно-телекомунікаційна система, яка є частиною Веб-сайту, за допомогою якої Користувач має можливість замовити Товари та/або Послуги, змінити умови надання Послуг, оплатити Товари та/або Послуги в порядку, передбаченому даним Договором. В особистому кабінеті зберігається інформація про Покупця та про замовлені ним Товари та\\або Послуги.",
                        ],
                    ],
                },
                block3: {
                    head: "ПРЕДМЕТ ДОГОВОРУ.",
                    list: [
                        "За цим договором Продавець зобов`язується передати у власність Покупця обрані Покупцем на відповідній сторінці Веб-сайту Товари та/або надати обрані Покупцем на відповідній сторінці Веб-сайту Послуги, а Покупець зобов’язується оплатити та отримати такі Товари та/або Послуги на умовах цього Договору.",
                        "Продавець має право залучати третіх осіб для виконання замовлення Покупця.",
                        "Продавець підтверджує, що має всі необхідні дозволи на здійснення господарської діяльності, які регулюють сферу правовідносин, що виникають та діють в процесі виконання цього Договору, а також гарантує, що має право на виробництво та/або реалізацію товару, та право на надання відповідних послуг без будь-яких обмежень у відповідності з вимогами діючого законодавства України, та зобов’язується нести відповідальність у випадку порушення прав Покупця в процесі виконання цього Договору.",
                        "Інформація про Товари та/або Послуги, яка відображена на Веб-сайті може містити неповні відомості, точна інформація може бути отримана Покупцем шляхом уточнення умов у відповідного менеджера Продавця за телефонами, вказаними у відповідному розділі Веб-сайту.",
                    ],
                },
                block4: {
                    head: "МОМЕНТ УКЛАДАННЯ ДОГОВОРУ",
                    list: [
                        "Моментом укладення даного Договору є момент замовлення Товару та/або Послуги Покупцем, не залежно від способу Замовлення.",
                        "Покупець зобов’язаний самостійно ознайомлюватися з умовами Договору, Продавець не зобов’язаний інформувати Покупця про наявність Договору.",
                        "У випадку замовлення Покупцем Товару та/або Послуги по телефону, в магазині Продавця, через електронну пошту або безпосередньо через Веб-сайт Продавця, Покупець підтверджує, що він ознайомився з даним Договором та погоджується з ним.",
                        "Покупець натискаючи кнопку «Підтвердити замовлення» приймає на себе зобов`язання оплати вартість таких Товарів та/або Послуг.",
                        "Цей Договір укладається шляхом прийняття умов даного Договору. Відсутність підписаного між сторонами примірника Договору на паперовому носії, з проставленням підписів і печаток сторін, в разі проведення по ньому фактичної оплати Покупцем, не є підставою вважати цей Договір неукладеним.",
                        "Продавець оформлює Договір на паперовому носії лише у разі письмової вимоги Покупця.",
                    ],
                },
                block5: {
                    head: "ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ ПОКУПЦЕМ.",
                    list: [
                        "Покупець самостійно оформлює Замовлення на відповідній сторінці Веб-сайту http://brusvyana.com.ua/ шляхом додавання Товарів та/або Послуг у Віртуальний кошик за допомогою натискання кнопки «Купити».",
                        "Після додавання у віртуальний кошик усіх бажаних Товарів та/або Послуг, Покупець оформлює Замовлення самостійно шляхом натискання кнопки «Оформити Замовлення» та заповнення відповідної форми на Веб-сайті http://brusvyana.com.ua/  , внесення контактних даних у обов’язкові до заповнення поля, внесення даних, необхідних для оплати та доставки Товару та/або надання вибраних Покупцем Послуг.",
                        "Завершення оформлення та направлення Замовлення Продавцю здійснюється після заповнення усіх необхідних полів у формі Сайту шляхом натисканням кнопки «Оформити Замовлення».",
                        "Внесення змін у оформлене Замовлення можливе протягом 60 хвилин з моменту остаточного оформлення Замовлення шляхом звернення до Продавця за телефоном, вказаним на Веб-сайті із повідомленням номеру замовлення та своїх контактних даних.",
                        "Оформлення замовлення також можливе за телефоном, вказаним у відповідному розділі Веб-сайту. В такому випадку Покупець повинен надати точне найменування обраного Товару та/або Послуги, марку, артикул, або інші данні, які дозволяють чітко та однозначно ідентифікувати Товар та/або Послугу, та надати Продавцю усі необхідні контактні данні про доставку та оплату.",
                        "Після отримання Замовлення Покупець перевіряє наявність обраного Покупцем Товару та/або можливість надання обраної Покупцем Послуги. Якщо виявиться що один або кілька Товарів з тих чи інших причин відсутні у Продавця на день Замовлення, або виявиться, що Продавець не зможе з тих чи інших причин надати обрану Покупцем Послугу, Продавець зв’язується за контактним номером з Покупцем для узгодження Замовлення – повідомляє про відсутні Товари та причини неможливості надати відповідні Послуги, та пропонує аналогічні чи схожі Товари та/або Послуги. Покупець повинен узгодити Замовлення з урахування такого повідомлення Продавця одразу, або до кінця дня у який оформив Замовлення, або на наступний робочий день Продавця (у разі оформлення Замовлення поза межами робочих годин Продавця).",
                        "Обробка замовлень здійснюється Продавцем за наступним графіком: з Понеділка по П`ятницю з 8:00 до 16:30.",
                    ],
                },
                block6: {
                    head: "ВАРТІСТЬ І ПОРЯДОК ОПЛАТИ ЗАМОВЛЕННЯ",
                    list: [
                        "Ціни на Товари та Послуги зазначені у відповідному розділі Веб-сайту Продавця. Всі ціни на Товари та Послуги відображені в національній валюті України (гривня) з урахуванням податків.",
                        "Зазначені на Веб-сайті Продавця ціни за Товар та Послуги носять ознайомчий характер та не включають вартість доставки, комісію і додаткові збори банків та платіжних систем. Покупець самостійно і за свій рахунок сплачує такі збори та вартість доставки. Розмір комісії або додаткового збору повідомляє відповідний банк та платіжна система.",
                        "Ціна Товарів та Послуг на Веб-сайті може бути змінена Продавцем в односторонньому порядку. При цьому ціна на замовлений Покупцем Товар та/або Послугу зміні не підлягає.",
                        "У разі неправильного зазначення ціни замовленого Покупцем Товару та/або Послуги, Продавець інформує про це Покупця для підтвердження Замовлення за виправленою ціною або анулювання Замовлення. При неможливості зв`язатися з Покупцем дане Замовлення вважається анульованим. Якщо Замовлення було оплачено, Продавець повертає Покупцеві сплачену за Замовлення суму на платіжну карту Покупця.",
                        "Загальна сума Замовлення складає вартість Товарів та/або Послуг та вартість доставки.",
                        "Перелік Товарів та/або Послуг підтвердженого Замовлення, а також загальна ціна Замовлення не можуть бути змінені після підтвердження Замовлення Продавцем, крім випадків, передбачених чинним законодавством України.",
                        "Покупець оплачує замовлення будь-яким способом, обраним при Замовленні Товарів та/або Послуг у відповідному розділі Веб-сайту Продавця.",
                        "Покупець може оплатити Замовлення наступними способами:",
                        [
                            "Готівкою в момент передачі Товару кур’єром та/або після надання обраної Послуги;",
                            "Готівкою або безготівковим способом при отриманні товару в магазині Продавця;",
                            "Накладним платежем при отриманні Замовлення в представництві служби доставки на території України;",
                            "Безготівково, за допомогою платіжної картки Visa або Mastercard безпосередньо на Веб-сайті Продавця.",
                            "При оплаті Замовлення онлайн, Користувач перенаправляється на платіжний сервіс LiqPay, де він має змогу сплатити за Замовлення за допомогою карток платіжних систем Visa та Mastercard.",
                        ],
                        "Оплата зараховується тільки за умови дотримання порядку, опублікованому на Веб-сайті Продавця.",
                        "У разі несплати, неповної оплати або несвоєчасної оплати Продавець залишає за собою право ненадання Товару та/або Послуги, призупинення або повне невиконання взятих на себе зобов’язань і при цьому не несе відповідальності за можливі наслідки такого рішення/дій.",
                        "Товари та Послуги гарантуються і надаються тільки за фактом отримання оплати Продавцем. До моменту отримання оплати Продавець не має ніяких зобов’язань перед Покупцем щодо Товарів та/або Послуг замовлених Покупцем",
                        "При безготівковій формі оплати обов`язок Покупця по сплаті вартості Замовлення вважається виконаним з моменту зарахування відповідних грошових коштів на розрахунковий рахунок Продавця.",
                        "При безготівковій формі розрахунку Покупець зобов`язаний пред`явити менеджеру або кур`єру документ, що підтверджує факт оплати Товару та/або Послуги (платіжне доручення, квитанція про сплату т.д.).",
                        "Продавець не несе відповідальності за порядок перерахування / зарахування коштів.",
                    ],
                },
                block7: {
                    head: "УМОВИ ПОВЕРНЕННЯ ТОВАРУ ТА ГРОШОВИХ КОШТІВ.",
                    list: [
                        "Обмін або повернення Товару належної якості Продавцем не здійснюється крім випадків, визначених в даном Договору.",
                        "Продавець допускає обмін або повернення в таких випадках:",
                        "У разі загибелі рослин, в період, що не перевищує 1 рік з моменту їх купівлі, та за умови виключення факту загибелі такої рослини у зв’язку з відсутністю належного догляду з боку Покупця (порушення правил поливу конкретного сорту/виду рослин). Встановлення відсутності факту неправильного догляду за рослиною, порушення правил поливу конкретного сорту/виду рослин здійснюється експертом Продавця безпосередньо в магазині Продавця. Встановлення відсутності факту неправильного догляду за рослинами, загальна вартість яких перевищує 50 000 грн. здійснюється експертом Продавця за місцем висадки таких рослин.",
                        "Повернення товару неналежної якості здійснюється відповідно до закону України «Про захист прав споживачів», у випадку наявності недоліків.",
                        "При виявленні недоліків Покупець зобов’язаний зафіксувати його у складеному акті довільної форми. Акт повинен бути підписаний Покупцем та особою, що здійснила доставку Товару або Продавцем.",
                        "У випадку повернення Товару, Покупець повинен повернути Товар кур`єрською/поштовою компанією, якою була здійснена доставка даного Замовлення, на адресу магазину, з якого було відправлено Замовлення або шляхом самостійної доставки такого товару до відповідного магазину Продавця.",
                        "Повернення Товару за адресою Продавця, здійснюється за рахунок Покупця та Продавцем Покупцеві не відшкодовується.",
                        "Відшкодування коштів Покупцю за Товар неналежної якості здійснюється за умови надання ним наступних документів:",
                        "Заяви на повернення товару/коштів;",
                        "Акту виявлених недоліків;",
                        "Документи, які підтверджують сплату (придбання) товару.",
                        "Заява на повернення товару/коштів має містити найменування товару, який повертається, суму в розмірі його вартості, поточний рахунок у національній валюті України Покупця (у випадку оплати товару безготівковим способом або за допомогою платіжних систем). Вартість за доставку замовлення поверненню не підлягає.",
                        "Повернення грошових коштів Покупцеві здійснюється за умови, що всі необхідні документи для повернення заповнені належним чином згідно вимог вказаних в п.7.7., 7.8. даного Договору та надані Продавцю.",
                        "У випадку повернення товарів, необхідною умовою для повернення коштів за повернений товар є надання Покупцем Продавцеві документів, якими підтверджується сплата (придбання) Товару, який повертається.",
                        "Повернення сплаченої за Товар суми здійснюється у той спосіб, яким була проведена оплата, або іншим способом, узгодженим Сторонами.",
                        "У випадку отримання товару у відділені Кур’єрської компанії, повернення коштів за повернений товар можливе лише безготівковим розрахунком, а саме: з поточного рахунку Продавця на поточний рахунок у національній валюті України Покупця.",
                        "Повернення коштів готівкою неможливе за виключенням, якщо оплата вартості Товару була здійснена готівкою у магазині.",
                        "Вимога (заява) про повернення грошової суми, сплаченої за товар неналежної якості, підлягає задоволенню протягом 7 робочих днів з дня пред`явлення відповідної вимоги (заяви).",
                    ],
                },
                block8: {
                    head: "УМОВИ ДОСТАВКИ ТОВАРУ.",
                    list: [
                        "Умови доставки та оплати Товару зазначені на відповідних розділах Веб-сайту Продавця. Всі питання, що виникли в процесі оплати і доставки Товару, Покупець може з’ясувати за контактними даними в розділі Контакти.",
                        "Покупець має право отримати замовлений ним Товар за допомогою самостійного вивозу (самовивіз) із магазину Продавця або скористатися послугами кур’єрської чи поштової доставки.",
                        "Самовивіз здійснюється Покупцем із магазину Продавця, який знаходиться за адресою Житомирська область, Брусилівський район, село Костівці, вулиця Польова 1, а також, з іншого погодженого сторонами місця.",
                        "Кур’єрська доставка замовлення Покупцю здійснюється за погодженням із Покупцем і за вказаною ним адресою.",
                        "Кур’єрська доставка замовленого Товару здійснюється в межах Бориспільського району та Київської області.",
                        "Доставка товару може здійснюватися власними силами співробітників Продавця, або із залученням третіх осіб (перевізника).",
                        "Факт отримання товару і відсутність претензій до якості товару Покупець підтверджує власним підписом при отриманні товару.",
                        "Продавець не несе відповідальності за термін доставки замовлення, в разі, коли доставка узгоджена і залежить від дій третіх осіб (перевізників). Продавець, на прохання Покупця може називати Покупцеві орієнтовну вартість доставки виключно в ознайомлювальних цілях.",
                        "Покупець розуміє і погоджується з тим, що: здійснення доставки – окрема послуга, яка не є невід’ємною частиною придбаного Покупцем Товару, виконання якої закінчується в момент отримання Одержувачем Товару і здійснення платежу за нього.",
                        "При прийнятті Замовлення від кур’єра, Покупець зобов’язаний оглянути доставлений Товар і перевірити його на відповідність заявленій якості, асортименту та комплектності Товару, а так само цілісність упаковки. У разі відсутності претензій до доставленого Товару, Покупець оплачує Замовлення.",
                        "Ризик випадкового пошкодження Товару переходить до Покупця з моменту передачі йому Замовлення і проставлення одержувачем Замовлення підпису в документах, що підтверджують доставку Замовлення.",
                        "У разі доставки Товару за допомогою служби доставки «Нова Пошта», підпис в декларації, яка надається службою, свідчить про те, що претензій до Товару отримувача не заявлено і Продавець повністю і належним чином виконав свій обов’язок щодо передачі Товару.",
                        "При передачі Товару Продавець або представник кур`єрської служби надає Покупцеві супровідний документ (видаткова накладна, чек) відповідно до Законодавства України.",
                    ],
                },
                block9: {
                    head: "ПРАВА ТА ОБОВЯЗКИ ПРОДАВЦЯ.",
                    list: [
                        "Продавець зобов’язаний:",
                        [
                            "Забезпечити продаж Товарів та/або надання Послуг у відповідності з цим Договором та вимогами чинного законодавства України;",
                            "Передати Покупцеві Товар належної якості та/або надати якісні Послуги у відповідності до умов цього Договору та Замовлення Покупця;",
                            "Перевірити якісні і кількісні характеристики Товару під час його пакування та відправки Покупцю;",
                            "Здійснити особисто або організувати доставку Товару Покупцеві, у разі, якщо Сторонами узгоджена доставка Товару;",
                            "Надати Покупцеві всю необхідну інформацію відповідно до вимог чинного законодавства і цієї Оферти;",
                            "З моменту укладення цього Договору забезпечити в повній мірі всі зобов`язання перед Покупцем відповідно до умов цього Договору;",
                            "Здійснювати повідомлення Покупців про зміну даного Договору шляхом розміщення відповідних змін на Веб-сайті;",
                            "Забезпечити виконання зобов’язань згідно з вимогами Закону України «Про захист прав споживачів»;",
                            "Забезпечити конфіденційність даних Покупця та дотримання законодавства України про захист персональних даних;",
                            "Розміщувати на Веб-сайті актуальну інформацію про Товари та Послуги та їхню вартість;",
                            "В разі доставки Товару неналежної якості, замінити такий Товар на Товар належної якості, або повернути сплачені кошти;",
                            "Виконувати інші обов’язки згідно з чинним законодавством та цим Договором;",
                        ],
                        "Продавець має право:",
                        [
                            "Вимагати оплати переданого Товару та/або наданої Послуги в порядку і на умовах, передбачених Договором;",
                            "Розірвати в односторонньому порядку цей Договір у випадку порушення Покупцем умов цього Договору;",
                            "Відмовити у виконанні Замовлення в разі виникнення форс-мажорних обставин;",
                            "Змінювати цей Договір в односторонньому порядку до моменту його укладення, шляхом розміщення змін на Веб-сайті;",
                            "Передати свої права і обов`язки по виконанню Замовлень третім особам;",
                            "Самостійно в односторонньому порядку змінювати ціни на Товар (в тому числі замовлений Покупцем) та асортимент Товару та/або ціни на Послуги (в тому числі замовлений Покупцем).",
                            "Змінювати в односторонньому порядку Замовлення, в тому числі повністю або частково відмовитись від виконання Замовлення, у випадку відсутності Товару або наявності причини неможливості надати відповідні Послуги на дату отримання Замовлення Покупцем;",
                            "Відмовитися від виконання укладеного Договору у випадку виявлення факту надання Покупцем недостовірної інформації; не здійснення Покупцем оплати товару та/або Послуг, неотримання Товару Покупцем в узгодженому місці та час, та/або допущення інших недобросовісних дій, які можуть мати наслідком ускладнення для Продавця або неможливість виконання цього Договору.",
                        ],
                    ],
                },
                block10: {
                    head: "ПРАВА ТА ОБОВЯЗКИ ПОКУПЦЯ.",
                    list: [
                        "Покупець зобов’язаний:",
                        "До моменту звернення в інтернет-магазин http://brusvyana.com.ua/ ознайомитися зі змістом Договору-оферти, умовами оплати та доставки на сайті магазину.",
                        "Надати Продавцю достовірну інформацію, необхідну для належного виконання Договору;",
                        "Своєчасно оплатити і отримати Замовлення на умовах цього Договору;",
                        "Ознайомитись з інформацією про Товар та/або Послугу, яка розміщена на Веб-сайті Продавця;",
                        "На виконання Продавцем своїх зобов`язань перед Покупцем останній повинен повідомити всі необхідні дані, які однозначно ідентифікують його як Покупця, і достатні для доставки Покупцеві сплаченого ним Товару або надання Покупцеві оплаченої ним Послуги;",
                        "При отриманні Товару у особи, що здійснила його доставку, впевнитися у цілісності та комплектності Товару шляхом огляду вмісту упаковки. У випадку виявлення пошкоджень чи неповної комплектації Товару – зафіксувати їх в акті, який разом із Покупцем повинна підписати особа, яка здійснила його доставку Покупцеві;",
                        "Добросовісно користуватися наданими йому правами, дотримуватися умов Договору та діючого законодавства;",
                        "Виконувати інші обов`язки, передбачені даним Договором та чинним законодавством України.",
                        "Покупець має право:",
                        "Оформити замовлення на відповідній сторінці Веб-сайту Продавця;",
                        "Вимагати від Продавця виконання умов цього Договору;",
                        "Вимагати від Продавця надання всієї необхідної інформації відповідно до вимог чинного законодавства і цієї Оферти;",
                        "Відмовитися від приймання Товару у випадку невідповідності його характеристик раніше замовленому товару;",
                        "Вимагати від Продавця заміни Товару неналежної якості на Товар належної якості або повернення сплачених коштів.",
                        "Здійснювати інші права, передбачені даним Договором та чинним законодавством України.",
                    ],
                },
                block11: {
                    head: "ВІДПОВІДАЛЬНІСТЬ СТОРІН.",
                    list: [
                        "Продавець не несе ніякої відповідальності за використання або подальше використання Товарів, придбаних Покупцем з використанням Веб-сайту Продавця, але не обмежуючись лише Веб-сайтом.",
                        "Покупець, оформляючи замовлення, несе відповідальність за достовірність наданої інформації про себе, а також підтверджує, що з умовами цього Договору ознайомлений і згоден.",
                        "Покупець самостійно несе повну відповідальність за надання помилкових або неправдивих відомостей, що спричинило неможливість належного виконання Продавцем своїх зобов’язань перед Покупцем.",
                        "У разі несплати, неповної або несвоєчасної сплати за Товар та/або Послуги Продавець вправі припинити виконання Замовлення та повідомити Покупця про несплату, неповну або несвоєчасну оплату Замовлення.",
                        "Покупець самостійно відповідає за правильність і своєчасність здійснених ним платежів. Суми, помилково зараховані Покупцем на рахунки третіх осіб, Продавцем не компенсуються та не відшкодовуються.",
                        "Продавець не несе відповідальності за збитки Покупця, що виникли в результаті відмови від Товару та/або Послуги у відповідності з вимогами даного Договору.",
                        "Продавець не несе відповідальність за цілісність та збереження Товару, у разі, якщо доставка Товару здійснюється не за допомогою власних сил співробітників Продавця.",
                        "Продавець не несе відповідальності за способи і результати використання Покупцем інформації, отриманої в ході виконання Замовлення Продавцем.",
                        "Продавець не буде нести відповідальності ні перед якою стороною за будь-який прямий, непрямий, особливий або інший непрямий збиток в результаті будь-якого використання інформації на Веб-сайті Продавця або на будь-якому іншому сайті, на який є посилання з Веб-сайту.",
                        "Продавець звільняється від відповідальності за неповне або неналежне виконання Замовлення за Договором у випадках невиконання Покупцем будь-якої з умов Договору.",
                        "У разі неотримання Покупцем Замовлення, якщо таке неотримання сталося з вини Покупця, Продавець має право використати такі Товари на власний розсуд та позбавляється обов’язку передати такі Товари Покупцю.",
                        "У разі порушення Покупцем умов даного Договору, Продавець має право припинити виконання Замовлення до моменту усунення Покупцем допущених порушень і відшкодування (компенсації) завданих Продавцю таким порушенням збитків та / або розірвати Договір з надсиланням відповідного повідомлення Покупцю.",
                        "Сукупна відповідальність Продавця за Договором, за будь-яким позовом або претензією, в тому числі зі Сторони Покупця щодо виконання Договору обмежується сукупною вартістю Замовлення Покупця.",
                        "Продавець не надає гарантій безперебійної роботи Веб-сайту і дотримання будь-яких термінів відновлення працездатності Веб-сайту в разі перерв у роботі з причин збоїв в телекомунікаційних і енергетичних мережах.",
                        "Сторони несуть іншу відповідальність за невиконання або неналежне виконання умов цього договору в порядку, передбаченому цим Договором та чинним законодавством України.",
                    ],
                },
                block12: {
                    head: "ФОРС-МАЖОРНІ ОБСТАВИНИ.",
                    list: [
                        "Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов`язань за умовами Договору на час дії непереборної сили. Під непереборною силою розуміються надзвичайні і непереборні за даних умов обставини, що перешкоджають виконанню своїх зобов`язань Сторонами за цим Договором. До них відносяться стихійні явища (землетруси, повені і т.д.), обставини суспільного життя (воєнні дії, надзвичайні стани, найбільші страйки, епідемії і т.д.), заборонні заходи державних органів (заборона перевезень, валютні обмеження, міжнародні санкції , заборони на торгівлю) та інше.",
                        "У випадку настання обставин визначених п. 12.1. даного Договору, виконання зобов`язань за цим договором відкладається на час дії таких обставин непереборної сили та їх наслідків.",
                        "На час дії обставин визначених п. 12.1. даного Договору сторони не мають взаємних претензій один до одного і кожна зі сторін приймає на себе свій ризик за результатами форс-мажорних обставин.",
                        "Якщо через дію обставин непереборної сили невиконання зобов’язань за цим Договором триває більше трьох місяців, кожна із Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону. Незважаючи на настання форс-мажору, перед припиненням цього Договору внаслідок форс-мажорних обставин Сторони здійснюють остаточні взаєморозрахунки.",
                        "Виникнення обставин непереборної сили не є підставою для відмови Покупця від здійснення оплати за Товар, який був йому поставлений до моменту виникнення обставин непереборної сили.",
                    ],
                },
                block13: {
                    head: "КОНФІДЕНЦІЙНІСТЬ",
                    list: [
                        "При акцепті даної оферти Покупець надає свою згоду на збір і обробку його персональних даних, відповідно до Закону України від 01 червня 2010 року № 2297- VI «Про захист персональних даних». Персональні дані Покупця обробляються відповідно до Закону України «Про захист персональних даних».",
                        "Дані, які надає Покупець під час розміщення Замовлення через Веб-сайт Продавця будуть використовуватися для обробки Замовлень на придбання Товарів та/або Послуг, отримання інформації про замовлення, відправки телекомунікаційними засобами зв`язку (по електронній пошті, за допомогою мобільного зв`язку) рекламних пропозицій, інформації про акції, розіграші, спеціальні пропозиції або будь-яку іншу інформацію про діяльність Продавця або його партнерів, в інших комерційних цілях.",
                        "Власником персональних даних є Фізична особа підприємець Дмитрієва Ліліана Вячеславівна код ЄДРПОУ 2707901127  , місце знаходження: 12613, Житомирська область, Брусилівський район, село Костівці, вулиця Польова 1.",
                        "Продавець гарантує конфіденційність і захист персональних даних Покупців.",
                        "Покупець дає право Продавцю здійснювати обробку його персональних даних, у тому числі: включати персональні дані до бази даних (без додаткового повідомлення про це), здійснювати безстрокове зберігання даних, їх накопичення, оновлення, зміну (за необхідності).",
                        "Продавець зобов`язується забезпечити захист даних від несанкціонованого доступу третіх осіб, не поширювати і не передавати дані третій стороні (крім передачі даних комерційним партнерам, особам, уповноваженим Продавцем на здійснення безпосередньої обробки даних для зазначених цілей, в тому числі операторам мобільного зв`язку, службам поштового зв`язку або кур`єрської доставки, контакт-центрам, рекламним агентствам, а також на запит компетентних органів державної влади у встановлених законом порядку).",
                        "Доступ до персональних даних Покупця мають тільки особи, які безпосередньо залучені до виконання Замовлення.",
                        "Продавець зобов`язується: дотримуватися конфіденційності щодо персональних даних Покупців; не допускати спроби несанкціонованого використання персональних даних Покупців третіми особами; виключити доступ до персональних даних Покупців, осіб, які не мають безпосереднього відношення до виконання Замовлення, крім випадків, передбачених чинним законодавством України.",
                        "Покупець має право відмовитися від отримання рекламної та іншої інформації за допомогою повідомлення на електронну пошту, вказану у розділі «Контакти» або в іншому місці на Веб-сайті Продавця.",
                        "Покупець має право запросити інформацію про те, обробляються чи його дані Продавцем. Продавець надає таку відповідь протягом 30 календарних днів.",
                        "Персональні данні, зібрані відповідно до даного договору, видаляються, як тільки вони більше не є необхідними для виконання мети їх збирання.",
                        "Покупець має право вимагати видалення та доступу до особистих даних, що обробляються/зберігаються Продавцем.",
                        "Вимога про видалення або доступ до особистих даних має бути вчинена Покупцем шляхом направлення листа на електронну пошту, вказану у розділі «Контакти» або в іншому місці на Веб-сайті Продавця, або шляхом направлення листа за місцезнаходженням Продавця.",
                        "Запит Покупця щодо видалення особистих даних буде розглядатися Продавцем, як вимога Покупця на розірвання цього Договору та відмова від подальшого отримання Товарів та/або Послуг Продавця.",
                        "Продавець вправі здійснювати записи телефонних розмов з Покупцем, попередньо повідомивши Покупця про такий запис. При цьому Продавець зобов`язується: запобігати спробам несанкціонованого доступу до інформації, отриманої в ході телефонних переговорів, та / або передачу її третім особам, які не мають безпосереднього відношення до виконання Замовлень.",
                    ],
                },
                block14: {
                    head: "ІНШІ УМОВИ.",
                    list: [
                        "Даний Договір набуває чинності з моменту прийняття оферти Покупцем і діє до повного виконання Сторонами своїх зобов`язань",
                        "Договір може бути розірваний в порядку, передбаченому чинним законодавством України",
                        "Фактичною датою укладання даного Договору між сторонами є дата прийняття умов даного Договору Покупцем, відповідно до ст. 11 Закону України «Про електронну комерцію»",
                        "Відповідно до статті 633 Цивільного кодексу України цей договір є публічною офертою (публічним договором), і в разі прийняття його умов (акцепту) Покупець зобов`язується сумлінно їх виконувати.",
                        "Усі спори та розбіжності, що виникли з приводу виконання умов цього Договору, підлягають вирішенню шляхом переговорів на підставі письмової заяви (претензії) Покупця.",
                        "Продавець після отримання від Покупця заяви (претензії), зобов`язаний протягом 20 (двадцяти) календарних днів задовольнити заявлені вимоги чи направити Покупцю мотивовану відмову.",
                        "В разі якщо спір, який виник, не буде врегульовано в претензійному порядку, Покупець має право звернутися до суду за місцем знаходження Продавця.",
                        "Використання ресурсу Веб-сайту Продавця для попереднього перегляду товару, а також для оформлення замовлення для Покупця є безкоштовним.",
                        "Всі повідомлення та інші документи за Договором повинні направлятися за адресами, вказаними в даному договорі та/або зазначеними у відповідному розділі Веб-сайту Продавця.",
                        "Вся текстова інформація та графічні зображення, що знаходяться на Веб-сайті є власністю Продавця.",
                        "Веб-сайт Продавця і надані сервіси можуть бути тимчасово частково або повністю недоступні унаслідок проведення профілактичних або інших робіт, або з будь-яких інших причин технічного характеру. Технічна служба Продавця має право періодично проводити необхідні профілактичні або інші роботи з попереднім повідомленням Покупців або без такого.",
                        "Продавець має право передавати свої права та обов`язки по виконанню Замовлень третім особам.",
                        "Покупець гарантує, що всі умови цього Договору йому зрозумілі, і він приймає їх безумовно і в повному обсязі.",
                        "Всі питання, неврегульовані даним Договором, регламентуються чинним законодавством України.",
                    ],
                },
                reqi: {
                    head: "РЕКВІЗИТИ ПРОДАВЦЯ.",
                    fop: "Фізична особа підприємець Дмитрієва Ліліана Вячеславівна",
                    ipn: "ІПН - 2707901127",
                    iban: 'П/р UA123052990000026009016402964 у банку АТ КБ "ПРИВАТБАНК"',
                },
            },
        },
        contacts: {
            head: "Контактні дані",
            smalPhone: "Торгові мережі/ дрібний опт",
            wholesale: "Оптова торгівля",
            retail: "Роздрібна торгівля",
            address:
                "Житомирська обл., Брусилівський р-н, с. Костівці, вул. Польова 1",
        },
    },
    ru: {
        header: {
            retail: "Розница",
            wholesale: "Опт",
            about: "О нас",
            contacts: "Контакты",
            projects: "Проекты",
            achievements: "Достижение",
            knowledges: "База знаний",
            documents: "Общая информация",
        },
        msgs: {
            notFound: "Страница не найдена",
            inDevelop: "Страница находится в разработке",
            sorry: "Извините за временные неудобства",
        },
        MainPage: {
            intro: {
                title: "Современные технологии размножения и доращивания саженцев",
                btnRetail: "Розница",
                btnWholesale: "Опт",
            },
            info: {
                headLeft:
                    "Современные технологии размножения и выращивания саженцев",
                leftParagraph: [
                    "Брусвяна - питомник ягодных культур с более чем 15-летним опытом успешной работы на рынке Украины и за ее пределами.",
                    "Основан в 2005 году. Одними из первых начали развивать популярную ныне культуру – голубику высокорослую.",
                    "Оказываем комплексную поддержку клиенту для обеспечения хорошего результата, от выбора культуры и сорта до агротехнических мероприятий.",
                    "Основными культурами производства является голубика высокорослая, малина ремонтантная, земляника альпийская.",
                    "Уникальная особенность питомника - собственная селекция: 6 сортов ремонтантной малины и 1 сорт голубики пользуются популярностью и занесены в реестр сортов Украины.",
                    "Бревенка является надежным партнером, предлагающим индивидуальные решения для каждой группы клиентов. Мы работаем с крупными и мелкими фермерскими хозяйствами, садовыми центрами, торговыми сетями, мелкими оптовыми клиентами.",
                    "Сотрудники компании от производства в отдел сбыта заботятся о качестве и скорости выполнения ваших заказов.",
                ],
                headRight:
                    "Уже более 16 лет качество, сортность и сервис – наш залог успеха!",
            },
            infoBlocks: {
                block1: {
                    title: "Растение выращено в год",
                    text: "1 200 000",
                },
                block2: {
                    title: "Сортов в коллекции",
                    text: "200",
                },
                block3: {
                    title: "Гектаров",
                    text: "1 000",
                },
            },
            bigBtns: {
                btnRetail: "Розничные предложения от Брусвяна",
                btnWholesale: "Оптовые предложения от Брусвяна",
                click: "Просмотреть ",
            },
            partners: {
                head: "Партнери",
                text: [
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                ],
            },
        },
        About: {
            aboutText: {
                head: "О нас",
                miniHead: "О компании",
                text1: "«Брусвяна» – украинская компания основана семьей Дмитриевых в 2005 году в селе Костовцы. Основное направление – производство саженцев ягодных культур: голубика, малина, земляника альпийская, облепиха. Сегодня «Брусвяна» имеет значительный опыт в области растениеводства, является одним из лидеров по своему делу, имеет авторские лицензии на собственные сорта. Активно участвует в мероприятиях по улучшению экосистемы планеты.",
                minihead2: "Селекция",
                text2: "За 15 лет существования специалистами компании было создано 6 сортов ремонтантной малины. К ним относятся Брусвяна, Брусиловская, Брусиловский стандарт, Примара, Ремарка и Ярославна. Брюсвен и Брусилов были отмечены наградами. Представленные сорта малины выращивались во многих регионах Украины и зарекомендовали себя надежными для выращивания в открытом грунте.",
                text22: "В 2008 году был создан первый отечественный сорт голубики – Фиолент, который был занесен в реестр сортов и пользуется популярностью среди садоводов и фермеров как сорт пригодный для механизированного сбора и переработки.",
                minihead3: "История",
                text31: "В 2005 году в селе Костовцы на площади 0,45 га был создан питомник и садовый центр Брусвяна. Активно размножался широкий спектр растений – как ягодные, плодовые, так и декоративные растения. В 2007-2009 годах появился запрос на промышленные ягодники. Как следствие, питомник увеличил площади и получил более узкую специализацию – ягодные культуры.",
                text32: "Сегодня Брусвяна – это современный комплекс размножения и адаптации растений, 4,5 га контейнерных площадок, 1 га теплиц, селекционный отдел. Начиная с 2005 года, мощности нашего производства выросли с 20 тыс. до 1,5 млн растений в год.",
                text33: "Компания занимается просветительской деятельностью, организуя обучающие семинары, конференции, форумы и другие массовые мероприятия. С 2013 года «Брусвяна» является организатором научно-практической конференции «Лохина высокорослая и другие ягодные культуры», которая впоследствии трансформировалась в ягодный форум. Участниками мероприятия были бизнесмены, ученые, мелкие и крупные фермеры. Для садоводов-любителей был организован «Ягодный Фестиваль Брусвяна-Украина».",
                text34: "Наша миссия – улучшение окружающей среды и повышение благосостояния общества.",
                text35: "Цель – создание, улучшение, выращивание и насаждение растений на планете.",
            },
            team: {
                head: "Команда",
                team: [
                    {
                        name: "Дмитриева Лилиана Вячеславовна",
                        img: "1.jpg",
                        position: "Владелец группы компании «Брусвяна»",
                        description: "Контакты: +380674102477",
                    },
                    {
                        name: "Евпак Екатерина Евгеньевна",
                        img: "2.jpg",
                        position: "Зам. директора по производству",
                        description:
                            "Контакты: brusvyana@ukr.net +380674113695",
                    },
                    {
                        name: "Король Евгений Антониевич",
                        img: "3.jpg",
                        position: "Директор садового центра Брусвяна",
                        description:
                            "Контакты: brusvyana@ukr.net +380674113692",
                    },
                    // {
                    //     name: "Горовая Татьяна Сергеевна",
                    //     img: "4.jpg",
                    //     position: "Менеджер по работе с оптовыми клиентами",
                    //     description:
                    //         "Контакты: brusvyana@ukr.net +380674113691",
                    // },
                ],
            },
        },
        Documents: {
            pay: {
                head: "Оплата",
                list: [
                    "Оплата на карту ПриватБанка через приложение Приват24 или через терминалы пополнения ПриватБанка, IBox. Сохраняйте чек после оплаты до момента подтверждения зачисления средств.",
                    'Оплата наложенного платежа в отделениях служб доставки "Новая Почта" после получения заказа и его проверки.',
                    "Оплата через сервис LiqPay, на ваш email будет выслан инвойс и реквизиты для оплаты.",
                ],
            },
            delivery: {
                head: "Доставка",
                text: "",
                list: [
                    "Самовывоз из садового центра «Брусвяна»",
                    "Доставка в отделение «Новой почты»",
                    "Адресная доставка «Новая почта»",
                    "Доставка крупногабаритного товара машиной перевозчика (стоимость предварительно уточняется в зависимости от региона доставки).",
                ],
            },
            refund: {
                head: "Гарантия и возврат",
                text: "Товар приобретен в садовом центре «Брусвяна», возврату и обмену не подлежит за исключением следующих моментов:",
                list: [
                    "Гарантия на растения действует при условии, что покупателем садового центра была оформлена услуга по высадке растений работниками предприятия. А также при наличии автоматического или капельного орошения на Вашем участке. В данном случае мы бесплатно произведем замену растения, в случае его гибели, в течение 1 (одного) календарного года после высадки.",
                    "Вы приобрели плодовые деревья в период март-май, но саженец «не проснулся». В течение 2-х месяцев от даты покупки растения Вы можете произвести обмен. При себе обязательно иметь товарный чек, в котором указано наименование саженца и контейнер со штрихкодом.",
                ],
            },
            policy: {
                head: "Пользовательское соглашение",
                block1: {
                    head: "ОБЩИЕ ПОЛОЖЕНИЯ",
                    list: [
                        "В соответствии со статьей 633 Гражданского кодекса Украины этот договор является официальным предложением Стороны 1 - ФИЗИЧЕСКОЕ ЛИЦО ПРЕДПРИНИМАТЕЛЬ ДМИТРИЕВА ЛИЛИАНА ВЯЧЕСЛАВОВНАЯ через Интернет-магазин http://brusvyana.com.ua/ (далее - договора о предоставлении услуг на изложенных в настоящей Оферте условиях.",
                        "Данное предложение является публичным договором-офертой (далее Договор) и имеет соответствующую юридическую силу, о продаже неограниченному кругу лиц (далее Покупатель) товаров, и предоставлению неограниченному кругу лиц Услуг, представленных в соответствующем разделе Веб-сайта Продавца.",
                        "Положения настоящего Договора регулируют взаимоотношения между Продавцом с одной стороны и Покупателем с другой стороны (при совместном упоминании именуемые - Стороны) на основании условий настоящего Договора.",
                        "Все пользователи Веб-сайта Продавца могут получить доступ к Договору (оферты) в любой момент, нажав на ссылку «Публичная оферта» на Сайте Продавца.",
                        "Настоящим Договором Стороны подтверждают достижение полного согласия между ними относительно правил, сроков, ограничений и других условий сотрудничества, изложенных в настоящем Договоре.",
                        "Данный договор с момента его заключения прекращает гарантии, пакты и любые другие договоренности (в письменной и устной форме) между Сторонами и имеет обязательную силу для Сторон.",
                        "Заключая Договор (т.е. акцептируя условия настоящего Предложения (путем оформления Заказа), Покупатель подтверждает следующее:",
                        [
                            "Покупатель целиком и полностью ознакомлен, и согласен с условиями этого предложения (оферты);",
                            "Предоставленная Покупателем информация при оформлении заказа Товаров или Услуг является правильной и актуальной;",
                            "Покупатель дает разрешение на сбор, обработку и передачу персональных данных с целью возможности выполнения условий настоящего Договора, возможности проведения взаиморасчетов, а также для получения счетов, актов и других документов. Разрешение на обработку персональных данных действует в течение всего срока действия Договора.",
                        ],
                    ],
                },
                block2: {
                    head: "ОПРЕДЕЛЕНИЕ И СРОКИ.",
                    list: [
                        "В этой оферте, если контекст не требует другого, следующие термины имеют следующее значение:",
                        [
                            "«Оферта» - публичное предложение Продавца, адресованное любому физическому и юридическому лицу, с целью заключить договор купли-продажи Товара, и/или договора предоставления услуг на существующих условиях, указанных в Договоре.",
                            "«Товар» - перечень наименований ассортимента, о приобретении которого на Веб-сайте http://brusvyana.com.ua/ размещено предложение Продавца, но не ограничиваясь только сайтом.",
                            "«Услуги» - перечень действий, совершаемых Продавцом за счет Покупателя из перечня услуг, доступных к заказу Покупателем на Веб-сайте http://brusvyana.com.ua/ .",
                            "«Продавец» - компания, являющаяся владельцем или распространителем Товара и реализующая такой Товар и предоставляющая соответствующие Услуги с помощью Веб-сайта http://brusvyana.com.ua/ .",
                            "«Покупатель» - физическое или юридическое лицо, вступившие в договорные отношения с Продавцом на условиях, указанных в настоящем Договоре.",
                            "«Акцепт» - полное и безоговорочное принятие Покупателем условий Договора.",
                            "«Веб-сайт» - автоматизированная информационная система, доступная в сети Интернет по сетевому адресу: http://brusvyana.com.ua/ , а также все «поддомены», относящиеся к данному адресу и используемые Продавцом на праве собственности.",
                            "«Заказ» – надлежаще оформленная и размещенная на Веб-сайте Продавца заявка Покупателя на покупку Товаров и/или заказ Услуг, адресуемый Продавцу.",
                            "«Курьерская доставка» - непосредственная передача Товара от работника службы доставки, или от работника Продавца, или от другого уполномоченного Продавцом лица Покупателю в месте, указанном Покупателем в качестве адреса доставки, на платной или безвозмездной условиях.",
                            "«Платежная система» – банк/участник платежной системы/оператор услуг платежной инфраструктуры, которая на договорной основе с Продавцом обеспечивает прием и перевод платежей с использованием реквизитов платежных карт Покупателей за Товары и/или Услуги, предлагаемые на Веб-сайте Продавца.",
                            "Личный кабинет – информационно-телекоммуникационная система, являющаяся частью Веб-сайта, с помощью которой Пользователь имеет возможность заказать Товары и/или Услуги, изменить условия предоставления Услуг, оплатить Товары и/или Услуги в порядке, предусмотренном настоящим Договором. В личном кабинете хранится информация о Покупателе и о заказанных им Товарах и/или Услуги.",
                        ],
                    ],
                },
                block3: {
                    head: "ПРЕДМЕТ ДОГОВОРА.",
                    list: [
                        "По настоящему договору Продавец обязуется передать в собственность Покупателя избранные Покупателем на соответствующей странице Веб-сайта Товары и/или предоставить избранные Покупателем на соответствующей странице Веб-сайта Услуги, а Покупатель обязуется оплатить и получить такие Товары и/или Услуги на условиях настоящего Договора.",
                        "Продавец имеет право привлекать третьих лиц для выполнения заказа Покупателя.",
                        "Продавец подтверждает, что имеет все необходимые разрешения на осуществление хозяйственной деятельности, которые регулируют сферу правоотношений, возникающих и действующих в процессе выполнения настоящего Договора, а также гарантирует, что имеет право на производство и/или реализацию товара и право на предоставление соответствующих услуг без каких-либо ограничений в соответствии с требованиями действующего законодательства Украины и обязуется нести ответственность в случае нарушения прав Покупателя в процессе выполнения настоящего Договора.",
                        "Информация о Товарах и/или Услуги, которая отображена на Веб-сайте может содержать неполную информацию, точная информация может быть получена Покупателем путем уточнения условий у соответствующего менеджера Продавца по телефонам, указанным в соответствующем разделе Веб-сайта.",
                    ],
                },
                block4: {
                    head: "МОМЕНТ ЗАКЛЮЧЕНИЯ ДОГОВОРА",
                    list: [
                        "Моментом заключения настоящего Договора является момент заказа Товара и/или Услуги Покупателем, не зависимо от способа Заказа.",
                        "Покупатель обязан самостоятельно ознакомляться с условиями Договора, Продавец не обязан информировать Покупателя о наличии Договора.",
                        "В случае заказа Покупателем Товара и/или По телефону, в магазине Продавца, по электронной почте или непосредственно через Веб-сайт Продавца, Покупатель подтверждает, что он ознакомился с настоящим Договором и соглашается с ним.",
                        "Покупатель нажимая кнопку «Подтвердить заказ» принимает на себя обязательство оплаты стоимости таких Товаров и/или Услуг.",
                        "Настоящий Договор заключается путем принятия условий настоящего Договора. Отсутствие подписанного между сторонами экземпляра Договора на бумажном носителе, с проставлением подписей и печатей сторон, в случае проведения по нему фактической оплаты Покупателем, не является основанием считать настоящий Договор незаключенным.",
                        "Продавец оформляет Договор на бумажном носителе только в случае письменного требования Покупателя.",
                    ],
                },
                block5: {
                    head: "ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА ПОКУПЦЕМ.",
                    list: [
                        "Покупатель самостоятельно оформляет Заказ на соответствующей странице Веб-сайта http://brusvyana.com.ua/ путем добавления Товаров и/или Услуг в Виртуальную корзину с помощью нажатия кнопки «Купить».",
                        "После добавления в виртуальную корзину всех желаемых Товаров и/или Услуг, Покупатель оформляет Заказ самостоятельно путем нажатия кнопки «Оформить Заказ» и заполнения соответствующей формы на Веб-сайте, http://brusvyana.com.ua/ , внесение контактных данных в обязательно обязательные для заполнения поля, внесения данных, необходимых для оплаты и доставки Товара и/или предоставления избранных Покупателем Услуг.",
                        "Завершение оформления и направления Заказа Продавцу осуществляется после заполнения всех необходимых полей в форме Сайта путем нажатия кнопки «Оформить Заказ».",
                        "Внесение изменений в оформленный Заказ возможно в течение 60 минут с момента окончательного оформления Заказа путем обращения к Продавцу по телефону, указанному на Веб-сайте с уведомлением номера заказа и своих контактных данных.",
                        "Оформление заказа возможно по телефону, указанному в соответствующем разделе Веб-сайта. В таком случае Покупатель должен предоставить точное наименование выбранного Товара и/или Услуги, марку, артикул или другие данные, позволяющие четко и однозначно идентифицировать Товар и/или Услугу и предоставить Продавцу все необходимые контактные данные о доставке и оплате.",
                        "После получения Заказа Покупатель проверяет наличие избранного Покупателем Товара и/или возможность предоставления Избранной Покупателем Услуги. Если окажется, что один или несколько Товаров по тем или иным причинам отсутствуют у Продавца на день Заказа, или окажется, что Продавец не сможет по тем или иным причинам предоставить выбранную Покупателем Услугу, Продавец связывается по контактному номеру с Покупателем для согласования Заказа – сообщает об отсутствующих Товарах и причинах невозможности предоставить соответствующие Услуги и предлагает аналогичные или похожие Товары и/или Услуги. Покупатель должен согласовать Заказ с учетом такого уведомления Продавца сразу или до конца дня, в который оформил Заказ, или на следующий рабочий день Продавца (в случае оформления Заказа за пределами рабочих часов Продавца).",
                        "Обработка заказов осуществляется Продавцом по следующему графику: с понедельника по пятницу с 8:00 до 16:30.",
                    ],
                },
                block6: {
                    head: "СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ ЗАКАЗ",
                    list: [
                        "Цены на Товары и Услуги указаны в соответствующем разделе Веб-сайта Продавца. Все цены на Товары и Услуги отражены в национальной валюте Украины (гривна) с учетом налогов.",
                        "Указанные на Веб-сайте Продавца цены за Товар и Услуги носят ознакомительный характер и не включают стоимость доставки, комиссию и дополнительные сборы банков и платежных систем. Покупатель самостоятельно и за свой счет уплачивает такие сборы и стоимость доставки. Размер комиссии или дополнительного сбора уведомляет соответствующий банк и платежная система.",
                        "Цена Товаров и Услуг на Веб-сайте может изменяться Продавцом в одностороннем порядке. При этом цена на заказанный Покупателем Товар и/или Услугу изменению не подлежит.",
                        "В случае неправильного указания цены заказа Покупателем Товара и/или Услуги, Продавец информирует об этом Покупателя для подтверждения Заказа по исправленной цене или аннулирования Заказа. При невозможности связаться с Покупателем настоящий Заказ считается аннулированным. Если Заказ был оплачен, Продавец возвращает Покупателю уплаченную за Заказ сумму на платежную карту Покупателя.",
                        "Общая сумма Заказа составляет стоимость Товаров и/или Услуг и стоимость доставки.",
                        "Перечень Товаров и/или Услуг подтвержденного Заказа, а также общая цена Заказа не могут быть изменены после подтверждения Заказа Продавцом, кроме случаев, предусмотренных действующим законодательством Украины.",
                        "Покупатель оплачивает заказ любым способом, выбранным при Заказе Товаров и/или Услуг в соответствующем разделе Веб-сайта Продавца.",
                        "Покупатель может оплатить Заказ следующими способами:",
                        [
                            "Наличными в момент передачи Товара курьером и/или после предоставления выбранной Услуги;",
                            "Наличными или безналичным способом при получении товара в магазине Продавца;",
                            "Накладным платежом при получении Заказа в представительстве службы доставки на территории Украины;",
                            "Безналично, с помощью платежной карты Visa или Mastercard непосредственно на Веб-сайте Продавца.",
                            "При оплате Заказ онлайн, Пользователь перенаправляется на платежный сервис LiqPay, где он может оплатить Заказ с помощью карт платежных систем Visa и Mastercard.",
                        ],
                        "Оплата зачисляется только при соблюдении порядка, опубликованном на Веб-сайте Продавца.",
                        "В случае неуплаты, неполной оплаты или несвоевременной оплаты Продавец оставляет за собой право непредоставления Товара и/или Услуги, приостановление или полное невыполнение взятых на себя обязательств и при этом не несет ответственности за возможные последствия такого решения/действий.",
                        "Товары и услуги гарантируются и предоставляются только по факту получения оплаты продавцом. До момента получения оплаты Продавец не имеет никаких обязательств перед Покупателем по Товарам и/или Услугам, заказанным Покупателем",
                        "При безналичной форме оплаты обязанность Покупателя по уплате стоимости Заказа считается выполненной с момента зачисления соответствующих денежных средств на расчетный счет Продавца.",
                        "При безналичной форме расчета Покупатель обязан предъявить менеджеру или курьеру документ, подтверждающий факт оплаты Товара и/или Услуги (платежное поручение, квитанция об уплате и т.д.).",
                        "Продавец не несет ответственности за порядок перечисления/зачисления средств.",
                    ],
                },
                block7: {
                    head: "УСЛОВИЯ ВОЗВРАЩЕНИЯ ТОВАРА И ДЕНЕЖНЫХ СРЕДСТВ.",
                    list: [
                        "Обмен или возврат Товара надлежащего качества Продавцом не производится кроме случаев, определенных в настоящем Договоре.",
                        "Продавец допускает обмен или возврат в следующих случаях:",
                        "В случае гибели растений, в период, не превышающий 1 года с момента их покупки, и при условии исключения факта гибели такого растения в связи с отсутствием надлежащего ухода со стороны Покупателя (нарушение правил полива конкретного сорта/вида растений). Установление отсутствия факта неправильного ухода за растением, нарушение правил полива конкретного сорта/вида растений производится экспертом Продавца непосредственно в магазине Продавца. Установление отсутствия факта неправильного ухода за растениями, общая стоимость которых превышает 50000 грн. осуществляется экспертом Продавца по месту высадки таких растений.",
                        "Возврат товара ненадлежащего качества осуществляется в соответствии с законом Украины «О защите прав потребителей», в случае наличия недостатков.",
                        "При обнаружении недостатков Покупатель обязан зафиксировать его в составленном акте произвольной формы. Акт должен быть подписан Покупателем и лицом, осуществившим доставку Товара или Продавцом.",
                        "В случае возврата Товара, Покупатель должен вернуть Товар курьерской/почтовой компанией, которой была произведена доставка данного Заказа, в адрес магазина, из которого был отправлен Заказ или путем самостоятельной доставки такого товара в соответствующий магазин Продавца.",
                        "Возврат Товара по адресу Продавца, осуществляется за счет Покупателя и Продавцем Покупателю не возмещается.",
                        "Возмещение средств Покупателю за Товар ненадлежащего качества осуществляется при предоставлении им следующих документов:",
                        "Заявления на возврат товара/средств;",
                        "Акта выявленных недостатков;",
                        "Документы, подтверждающие уплату (приобретение) товара.",
                        "Заявление на возврат товара/средств должно содержать наименование возвращаемого товара сумму в размере его стоимости, текущий счет в национальной валюте Украины Покупателя (в случае оплаты товара безналичным способом или с помощью платежных систем). Стоимость за доставку заказа возврату не подлежит.",
                        "Возврат денежных средств Покупателю осуществляется при условии, что все необходимые документы для возврата заполнены должным образом согласно требованиям указанным в п.7.7., 7.8. настоящего Договора и предоставлены Продавцу.",
                        "В случае возврата товаров, необходимым условием для возврата средств за возвращенный товар является предоставление Покупателем Продавцу документов, которыми подтверждается уплата (приобретение) Возвращаемого товара.",
                        "Возвращение уплаченной за Товар суммы осуществляется тем способом, которым была произведена оплата, или иным способом, согласованным Сторонами.",
                        "В случае получения товара в отделении Курьерской компании, возврат средств за возвращенный товар возможен только безналичным расчетом, а именно: с текущего счета Продавца на текущий счет в национальной валюте Украины Покупателя.",
                        "Возврат средств наличными невозможно за исключением, если оплата стоимости Товара была произведена наличными в магазине.",
                        "Требование (заявление) о возврате денежной суммы, уплаченной за товар ненадлежащего качества, подлежит удовлетворению в течение 7 рабочих дней со дня предъявления соответствующего требования (заявления).",
                    ],
                },
                block8: {
                    head: "УСЛОВИЯ ДОСТАВКИ ТОВАРА.",
                    list: [
                        "Условия доставки и оплаты Товара указаны на соответствующих разделах Веб-сайта Продавца. Все вопросы, возникшие в процессе оплаты и доставки Товара, Покупатель может узнать по контактным данным в разделе Контакты.",
                        "Покупатель имеет право получить заказанный им Товар с помощью самостоятельного вывоза (самовывоз) из магазина Продавца или воспользоваться услугами курьерской или почтовой доставки.",
                        "Самовывоз осуществляется Покупателем из магазина Продавца, который находится по адресу Житомирская область, Брусиловский район, село Костовцы, улица Полевая 1, а также, с другого согласованного сторонами места.",
                        "Курьерская доставка заказа Покупателю осуществляется по согласованию с Покупателем и по указанному им адресу.",
                        "Курьерская доставка заказанного Товара осуществляется в пределах Бориспольского района и Киевской области.",
                        "Доставка товара может осуществляться собственными силами сотрудников Продавца, или с привлечением третьих лиц (перевозчика).",
                        "Факт получения товара и отсутствие претензий к качеству товара Покупатель подтверждает собственной подписью при получении товара.",
                        "Продавец не несет ответственности за срок доставки заказа, в случае, если доставка согласована и зависит от действий третьих лиц (перевозчиков). Продавец, по просьбе Покупателя, может называть Покупателю ориентировочную стоимость доставки исключительно в ознакомительных целях.",
                        "Покупатель понимает и соглашается с тем, что: осуществление доставки – отдельная услуга, которая не является неотъемлемой частью приобретенного Покупателем Товара, выполнение которой истекает в момент получения Получателем Товара и осуществления платежа за него.",
                        "При принятии Заказа от курьера, Покупатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному качеству, ассортименту и комплектности Товара, а так же целостность упаковки. В случае отсутствия претензий к доставленному Товару, Покупатель оплачивает Заказ.",
                        "Риск случайного повреждения Товара переходит к Покупателю с момента передачи ему Заказа и проставления получателем Заказа подписи в документах, подтверждающих доставку Заказа.",
                        "В случае доставки Товара с помощью службы доставки «Новая Почта», подпись в декларации, предоставляемой службой, свидетельствует о том, что претензий к Товару получателя не заявлено и Продавец полностью и должным образом выполнил свой долг по передаче Товара.",
                        "При передаче Товара Продавец или представитель курьерской службы предоставляет Покупателю сопроводительный документ (расходная накладная, чек) в соответствии с Законодательством Украины.",
                    ],
                },
                block9: {
                    head: "ПРАВА И ОБЯЗАННОСТИ ПРОДАВЦА.",
                    list: [
                        "Продавец обязан:",
                        "Обеспечить продажу Товаров и/или предоставление Услуг в соответствии с настоящим Договором и требованиями действующего законодательства Украины;",
                        "Передать Покупателю Товар надлежащего качества и/или предоставить качественные Услуги в соответствии с условиями настоящего Договора и Заказом Покупателя;",
                        "Проверить качественные и количественные характеристики Товара при его упаковке и отправке Покупателю;",
                        "Совершить лично или организовать доставку Товара Покупателю, в случае, если Сторонами согласована доставка Товара;",
                        "Предоставить Покупателю всю необходимую информацию в соответствии с требованиями действующего законодательства и настоящей Оферты;",
                        "С момента заключения настоящего Договора обеспечить в полной мере все обязательства перед Покупателем в соответствии с условиями настоящего Договора;",
                        "Создавать уведомления Покупателей об изменении настоящего Договора путем размещения соответствующих изменений на Веб-сайте;",
                        "Обеспечить исполнение обязательств согласно требованиям Закона Украины «О защите прав потребителей»;",
                        "Обеспечить конфиденциальность данных Покупателя и соблюдение законодательства Украины о защите персональных данных;",
                        "Размещать на Веб-сайте актуальную информацию о Товарах и Услуги и их стоимости;",
                        "В случае доставки Товара ненадлежащего качества, заменить такой Товар на Товар надлежащего качества или вернуть уплаченные средства;",
                        "Выполнять другие обязанности согласно действующему законодательству и настоящему Договору;",
                        "Продавец имеет право:",
                        "Требовать оплаты переданного Товара и/или предоставленной Услуги в порядке и на условиях, предусмотренных Договором;",
                        "Разорвать в одностороннем порядке настоящий Договор в случае нарушения Покупателем условий настоящего Договора;",
                        "Отказать в выполнении Заказа в случае возникновения форс-мажорных обстоятельств;",
                        "Изменять настоящий Договор в одностороннем порядке до момента его заключения, путем размещения изменений на Веб-сайте;",
                        "Передать свои права и обязанности по выполнению Заказов третьим лицам;",
                        "Самостоятельно в одностороннем порядке изменять цены на Товар (в том числе заказанный Покупателем) и ассортимент Товара и/или цены на Услуги (в том числе заказанный Покупателем).",
                        "Изменять в одностороннем порядке Заказ, в том числе полностью или частично отказаться от выполнения Заказа, в случае отсутствия Товара или наличия причины невозможности предоставить соответствующие Услуги на дату получения Заказа Покупателем;",
                        "Отказаться от выполнения заключенного Договора в случае выявления факта предоставления Покупателем недостоверной информации; не совершение Покупателем оплаты товара и/или Услуг, неполучение Товара Покупателем в согласованном месте и времени, и/или допущение других недобросовестных действий, которые могут повлечь затруднение Продавца или невозможность выполнения настоящего Договора.",
                    ],
                },
                block10: {
                    head: "ПРАВА И ОБЯЗАННОСТИ ПОКУПЦА.",
                    list: [
                        "Покупатель обязан:",
                        "До момента обращения в интернет-магазин http://brusvyana.com.ua/ ознакомиться с содержанием Договора-оферты, условиями оплаты и доставки на сайте магазина.",
                        "Предоставить Продавцу достоверную информацию, необходимую для надлежащего выполнения Договора;",
                        "Своевременно оплатить и получить Заказы на условиях настоящего Договора;",
                        "Ознакомиться с информацией о Товаре и/или Услуге, размещенной на Веб-сайте Продавца;",
                        "Во исполнение Продавцом своих обязательств перед Покупателем последний должен сообщить все необходимые данные, которые однозначно идентифицируют его как Покупателя, и достаточные для доставки Покупателю оплаченного им Товара или предоставления Покупателю оплаченной им Услуги;",
                        "При получении Товара у лица, осуществившего его доставку, убедиться в целостности и комплектности Товара путем осмотра содержимого упаковки. В случае обнаружения повреждений или неполной комплектации Товара – зафиксировать их в акте, который вместе с Покупателем должно подписать лицо, осуществившее его доставку Покупателю;",
                        "Добросовестно пользоваться предоставленными ему правами, соблюдать условия Договора и действующее законодательство;",
                        "Выполнять другие обязанности, предусмотренные настоящим Договором и действующим законодательством Украины.",
                        "Покупатель имеет право:",
                        "Оформить заказ на соответствующей странице Веб-сайта Продавца;",
                        "Требовать от Продавца выполнения условий настоящего Договора;",
                        "Требовать от Продавца предоставления всей необходимой информации в соответствии с требованиями действующего законодательства и настоящей Оферты;",
                        "Отказаться от приемки Товара в случае несоответствия его характеристик ранее заказанному товару;",
                        "Требовать от Продавца замены Товара ненадлежащего качества на Товар надлежащего качества или возврата уплаченных средств.",
                        "Осуществлять другие права, предусмотренные настоящим Договором и действующим законодательством Украины.",
                    ],
                },
                block11: {
                    head: "ОТВЕТСТВЕННОСТЬ СТОРОН.",
                    list: [
                        "Продавец не несет никакой ответственности за использование или дальнейшее использование Товаров, приобретенных Покупателем с использованием Веб-сайта Продавца, но не ограничиваясь только Веб-сайтом.",
                        "Покупатель, оформляя заказ, несет ответственность за достоверность предоставленной информации о себе, а также подтверждает, что с условиями настоящего Договора ознакомлен и согласен.",
                        "Покупатель самостоятельно несет полную ответственность за предоставление ложных или ложных сведений, что повлекло за собой невозможность надлежащего выполнения Продавцом своих обязательств перед Покупателем.",
                        "В случае неуплаты, неполной или несвоевременной оплаты за Товар и/или Услуги Продавец вправе прекратить выполнение Заказа и сообщить Покупателю о неуплате, неполной или несвоевременной оплате Заказа.",
                        "Покупатель самостоятельно отвечает за правильность и своевременность совершенных им платежей. Суммы, ошибочно зачисленные Покупателем на счета третьих лиц, Продавцом не возмещаются и не возмещаются.",
                        "Продавец не несет ответственности за убытки Покупателя, возникшие в результате отказа от Товара и/или Услуги в соответствии с требованиями настоящего Договора.",
                        "Продавец не несет ответственности за целостность и сохранение Товара, в случае, если доставка Товара осуществляется не с помощью собственных сил сотрудников Продавца.",
                        "Продавец не несет ответственности за способы и результаты использования Покупателем информации, полученной в ходе выполнения Заказа Продавцом.",
                        "Продавец не будет нести ответственности ни перед какой стороной за какой-либо прямой, косвенный, особый или другой косвенный ущерб в результате какого-либо использования информации на Веб-сайте Продавца или на любом другом сайте, на который есть ссылки с Веб- сайта.",
                        "Продавец освобождается от ответственности за неполное или ненадлежащее исполнение Заказа по Договору в случаях невыполнения Покупателем любого из условий Договора.",
                        "В случае неполучения Покупателем Заказа, если такое неполучение произошло по вине Покупателя, Продавец имеет право использовать такие Товары по своему усмотрению и лишается обязанности передать такие Товары Покупателю.",
                        "В случае нарушения Покупателем условий настоящего Договора, Продавец имеет право прекратить выполнение Заказа до момента устранения Покупателем допущенных нарушений и возмещения (компенсации) причиненных Продавцу таким нарушением ущерба и/или расторгнуть Договор с отправкой соответствующего уведомления Покупателю.",
                        "Совокупная ответственность Продавца по Договору, по любому иску или претензии, в том числе из Стороны Покупателя по выполнению Договора ограничивается совокупной стоимостью Заказа Покупателя.",
                        "Продавец не предоставляет гарантий бесперебойной работы Веб-сайта и соблюдения каких-либо сроков восстановления работоспособности Веб-сайта в случае перерывов в работе по причинам сбоев в телекоммуникационных и энергетических сетях.",
                        "Стороны несут другую ответственность за невыполнение или ненадлежащее выполнение условий настоящего договора в порядке, предусмотренном настоящим Договором и действующим законодательством Украины.",
                    ],
                },
                block12: {
                    head: "ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА.",
                    list: [
                        "Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по условиям Договора на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые в данных условиях обстоятельства, препятствующие выполнению своих обязательств Сторонами по настоящему Договору. К ним относятся стихийные явления (землетрясения, наводнения и т.д.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, величайшие забастовки, эпидемии и т.д.), запретительные меры государственных органов (запрет перевозок, валютные ограничения, международные санкции , запреты на торговлю).",
                        "В случае наступления обстоятельств определенных п. 12.1. настоящего Договора, выполнение обязательств по этому договору откладывается на время действия таких обстоятельств непреодолимой силы и их последствий.",
                        "На время действия обстоятельств, определенных п. 12.1. настоящего Договора стороны не имеют взаимных претензий друг к другу и каждая из сторон принимает на себя свой риск по результатам форс-мажорных обстоятельств.",
                        "Если из-за действия обстоятельств непреодолимой силы неисполнения обязательств по настоящему Договору длится более трех месяцев, каждая из Сторон имеет право расторгнуть настоящий Договор в одностороннем порядке, письменно уведомив об этом другую сторону. Несмотря на наступление форс-мажора, перед прекращением настоящего Договора в результате форс-мажорных обстоятельств Стороны осуществляют окончательные взаиморасчеты.",
                        "Возникновение обстоятельств непреодолимой силы не является основанием для отказа Покупателя от осуществления оплаты за Товар, который был ему поставлен до момента возникновения обстоятельств непреодолимой силы.",
                    ],
                },
                block13: {
                    head: "КОНФИДЕНЦИАЛЬНОСТЬ",
                    list: [
                        "При акцепте данной оферты Покупатель дает свое согласие на сбор и обработку его персональных данных, согласно Закону Украины от 01 июня 2010 года № 2297-VI «О защите персональных данных». Персональные данные Покупателя обрабатываются в соответствии с Законом Украины «О защите персональных данных».",
                        "Данные, которые предоставляет Покупатель при размещении Заказа через Веб-сайт Продавца будут использоваться для обработки Заказов на приобретение Товаров и/или Услуг, получения информации о заказе, отправки телекоммуникационными средствами связи (по электронной почте, с помощью мобильной связи ) рекламных предложений, информации об акциях, розыгрышах, специальных предложениях или любой другой информации о деятельности Продавца или его партнеров, в других коммерческих целях.",
                        "Владельцем персональных данных является Физическое лицо предприниматель Дмитриева Лилиана Вячеславовна код ЕГРПОУ 2707901127 , местонахождение: 12613, Житомирская область, Брусиловский район, село Костовцы, улица Полевая 1.",
                        "Продавец гарантирует конфиденциальность и защиту персональных данных Покупателей.",
                        "Покупатель дает право Продавцу производить обработку его персональных данных, в том числе: включать персональные данные в базу данных (без дополнительного уведомления об этом), осуществлять бессрочное хранение данных, их накопление, обновление, изменение (при необходимости).",
                        "Продавец обязуется обеспечить защиту данных от несанкционированного доступа третьих лиц, не распространять и не передавать данные третьей стороне (кроме передачи данных коммерческим партнерам, лицам, уполномоченным Продавцом на осуществление непосредственной обработки данных для указанных целей, в том числе операторам мобильной связи , службам почтовой связи или курьерской доставки, контакт-центрам, рекламным агентствам, а также по запросу компетентных органов государственной власти в установленных законом порядке).",
                        "Доступ к персональным данным Покупателя имеют только лица, непосредственно вовлеченные в исполнение Заказа.",
                        "Продавец обязуется: соблюдать конфиденциальность в отношении персональных данных Покупателей; не допускать попытки несанкционированного использования персональных данных Покупателей третьими лицами; исключить доступ к персональным данным Покупателей, лиц, не имеющих непосредственного отношения к выполнению Заказа, кроме случаев, предусмотренных действующим законодательством Украины.",
                        'Покупатель имеет право отказаться от получения рекламной и другой информации посредством сообщения на электронную почту, указанную в разделе "Контакты" или в другом месте на Веб-сайте Продавца.',
                        "Покупатель имеет право запросить информацию о том, обрабатываются ли его данные Продавцом. Продавец дает такой ответ в течение 30 календарных дней.",
                        "Персональные данные, собранные в соответствии с настоящим договором, удаляются, как только они больше не необходимы для выполнения цели их сбора.",
                        "Покупатель имеет право требовать удаления и доступа к личным данным, которые обрабатываются/сохраняются Продавцом.",
                        "Требование об удалении или доступе к личным данным должно быть совершено Покупателем путем направления письма на электронную почту, указанную в разделе «Контакты» или в другом месте на Веб-сайте Продавца, или путем направления письма по местонахождению Продавца.",
                        "Запрос Покупателя относительно удаления личных данных будет рассматриваться Продавцом как требование Покупателя на расторжение настоящего Договора и отказ от дальнейшего получения Товаров и/или Услуг Продавца.",
                        "Продавец вправе осуществлять записи телефонных разговоров с Покупателем, предварительно уведомив Покупателя о такой записи. При этом Продавец обязуется: предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к выполнению Заказов.",
                    ],
                },
                block14: {
                    head: "ПРОЧИЕ УСЛОВИЯ.",
                    list: [
                        "Данный Договор вступает в силу с момента принятия оферты Покупателем и действует до полного исполнения Сторонами своих обязательств",
                        "Договор может быть расторгнут в порядке, предусмотренном действующим законодательством Украины",
                        "Фактической датой заключения настоящего Договора между сторонами является дата принятия условий настоящего Договора Покупателем, согласно ст. 11 Закона Украины «Об электронной коммерции»",
                        "В соответствии со статьей 633 Гражданского кодекса Украины этот договор является публичной офертой (публичным договором), и в случае принятия его условий (акцепта) Покупатель обязуется добросовестно их выполнять.",
                        "Все споры и разногласия, возникшие по поводу выполнения условий настоящего Договора, подлежат разрешению путем переговоров на основании письменного заявления (претензии) Покупателя.",
                        "Продавец после получения от Покупателя заявления (претензии), обязан в течение 20 (двадцати) календарных дней удовлетворить заявленные требования или направить Покупателю мотивированный отказ.",
                        "В случае если возникший спор не будет урегулирован в претензионном порядке, Покупатель имеет право обратиться в суд по месту нахождения Продавца.",
                        "Использование ресурса Веб-сайта Продавца для предварительного просмотра товара, а также для оформления заказа для Покупателя является бесплатным.",
                        "Все уведомления и другие документы по Договору должны направляться по адресам, указанным в настоящем договоре и/или указанным в соответствующем разделе Веб-сайта Продавца.",
                        "Вся текстовая информация и графические изображения, находящиеся на Веб-сайте, являются собственностью Продавца.",
                        "Веб-сайт Продавца и предоставленные сервисы могут быть временно частично или полностью недоступны по причине проведения профилактических или других работ, или по любым другим причинам технического характера. Техническая служба Продавца имеет право периодически проводить необходимые профилактические или другие работы с предварительным уведомлением Покупателей или без такового.",
                        "Продавец имеет право передавать свои права и обязанности по выполнению Заказов третьим лицам.",
                        "Покупатель гарантирует, что все условия настоящего Договора ему понятны, и он принимает их безусловно и в полном объеме.",
                        "Все вопросы, неурегулированные настоящим Договором, регламентируются действующим законодательством Украины.",
                    ],
                },
                reqi: {
                    head: "РЕКВИЗИТЫ ПРОДАВЦА.",
                    fop: "Дмитриева Лилиана Вячеславовна, предприниматель",
                    ipn: "ИНН - 2707901127",
                    iban: 'iban UA123052990000026009016402964 JSC CB "PRIVATBANK"',
                },
            },
        },
        contacts: {
            head: "Контактные данные",
            smalPhone: "Торговые сети/ мелкий опт",
            wholesale: "Оптовая торговля",
            retail: "Розничная торговля",
            address:
                "Житомирская область, Брусиловский р-н, с. Костовцы, ул. Полевая 1",
        },
    },
    en: {
        header: {
            retail: "Retail",
            wholesale: "Wholesale",
            about: "About",
            contacts: "Contacts",
            projects: "Projects",
            achievements: "Achievements",
            knowledges: "Knowledge base",
            documents: "Documents",
        },

        msgs: {
            notFound: "Page not found",
            inDevelop: "Page is under construction",
            sorry: "Sorry for the temporary inconvenience",
        },
        MainPage: {
            intro: {
                title: "Modern technologies of propagation and growing of seedlings",
                btnRetail: "Retail",
                btnWholesale: "Wholesale",
            },
            info: {
                headLeft:
                    "Modern technologies of propagation and growing of seedlings",
                leftParagraph: [
                    "Brusvyana is a nursery of berry crops with more than 15 years of successful experience in the market of Ukraine and beyond.",
                    "Founded in 2005. They were among the first to develop the now popular culture - tall blueberry.",
                    "We provide comprehensive support to the client to ensure a good result, from the choice of culture and variety to agrotechnical measures.",
                    "The main production crops are high-growing blueberries, remontant raspberries, and alpine strawberries.",
                    "A unique feature of the nursery is its own selection: 6 varieties of remontant raspberry and 1 variety of blueberry are popular and included in the register of varieties of Ukraine.",
                    "Brevenka is a reliable partner offering customized solutions for each group of customers. We work with large and small farms, garden centers, retail chains, small wholesale customers.",
                    "Employees of the company from production to the sales department care about the quality and speed of your orders.",
                ],
                headRight:
                    "For more than 16 years, quality, quality and service have been our key to success!",
            },
            infoBlocks: {
                block1: {
                    title: "The plants were grown in a year",
                    text: "1 200 000",
                },
                block2: {
                    title: "Varieties in the collection",
                    text: "200",
                },
                block3: {
                    title: "Hectares",
                    text: "1 000",
                },
            },
            bigBtns: {
                btnRetail: "Retail offers from Brusvyan",
                btnWholesale: "Wholesale offers from Brusvyan",
                click: "Review",
            },
            partners: {
                head: "Партнери",
                text: [
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                    "Розділ наповнюється",
                ],
            },
        },
        About: {
            aboutText: {
                head: "About us",
                miniHead: "About the company",
                text1: "'Brusvyana' is a Ukrainian company founded by the Dmitriev family in 2005 in the village of Kostivtsi. The main direction is the production of seedlings of berry crops: blueberries, raspberries, alpine strawberries, sea buckthorn. Today, 'Brusvyana' has considerable experience in the field of plant breeding, is one of the leaders in its field, and has copyright licenses for its own varieties. Takes an active part in measures to improve the planet's ecosystem.",
                minihead2: "Selection",
                text2: "Over 15 years of existence, the company's specialists have created 6 varieties of remontant raspberries. These include Brusvyana, Brusylivska, Brusylivska standard, Primara, Remarka and Yaroslavna. Brusvyana and Brusylivska were honored with awards. The raspberry varieties presented were grown in many regions of Ukraine and have proven to be reliable for growing in open ground.",
                text22: "In 2008, the first domestic variety of blueberry was created - Fiolent, which was entered into the register of varieties and is popular among gardeners and farmers as a variety suitable for mechanized harvesting and processing.",
                minihead3: "History",
                text31: "In 2005, the Brusvyan nursery and garden center was established in the village of Kostivtsi on an area of 0.45 ha. A wide range of plants - both berry, fruit, and decorative plants - was actively reproduced. In 2007-2009, there was a demand for industrial berries. As a result, the nursery increased its area and received a narrower specialization - berry crops.",
                text32: "Today, Brusvyana is a modern plant breeding and adaptation complex, 4.5 ha of container sites, 1 ha of greenhouses, and a selection department. Since 2005, our production capacity has grown from 20,000 to 1.5 million plants per year.",
                text33: "The company is engaged in educational activities by organizing educational seminars, conferences, forums and other mass events. Since 2013, 'Brusvyana' has been the organizer of the scientific-practical conference 'Tall bilberry and other berry crops', which was later transformed into a berry forum. The participants of the event were businessmen, scientists, small and large farmers. For amateur gardeners, the 'Brusvyan Berry Festival-Ukraine' was organized.",
                text34: "Our mission is to improve the environment and increase the welfare of society.",
                text35: "The goal is to create, improve, grow and plant plants on the planet.",
            },
            team: {
                head: "Team",
                team: [
                    {
                        name: "Dmitrieva Liliana Vyacheslavivna",
                        img: "1.jpg",
                        position: 'Owner of the "Brusvyana" group of companies',
                        description: "Contacts: +380674102477",
                    },
                    {
                        name: "Yevpak Kateryna Evgenivna",
                        img: "2.jpg",
                        position: "Deputy production director",
                        description:
                            "Contacts: brusvyana@ukr.net +380674113695",
                    },
                    {
                        name: "King Evgeny Antoniyovych",
                        img: "3.jpg",
                        position: "Director of Brusvyan garden center",
                        description:
                            "Contacts: brusvyana@ukr.net +380674113692",
                    },
                    // {
                    //     name: "Horova Tetyana Serhiivna",
                    //     img: "4.jpg",
                    //     position: "Manager for work with wholesale customers",
                    //     description:
                    //         "Contacts: brusvyana@ukr.net +380674113691",
                    // },
                ],
            },
        },
        Documents: {
            pay: {
                head: "Payment",
                list: [
                    "Payment to a PrivatBank card through the Privat24 application or through PrivatBank top-up terminals, IBox. Keep the check after payment until confirmation of funds transfer.",
                    'Payment of cash on delivery at the "Nova Poshta" delivery service branches, after receiving the order and checking it.',
                    "Payment through the LiqPay service, an invoice and payment details will be sent to your email.",
                ],
            },
            delivery: {
                head: "Delivery",
                text: "",
                list: [
                    'Self-pickup from the garden center "Brusvyana"',
                    'Delivery to the "New Poshta" branch',
                    'Nova Poshta" address delivery',
                    "Delivery of oversized goods by the carrier`s car (the cost is pre-specified depending on the region of delivery).",
                ],
            },
            refund: {
                head: "Warranty and returns",
                text: "The product purchased at the garden center 'Brusvyana' cannot be returned or exchanged, except for the following points:",
                list: [
                    "The guarantee for plants is valid on the condition that the buyer of the garden center has arranged a plant planting service by the company`s employees. And also if there is automatic or drip irrigation on your site. In this case, we will replace the plant free of charge, in case of its death, within 1 (one) calendar year after planting.",
                    'You purchased fruit trees in March-May, but the seedling "did not wake up". Within 2 months from the date of purchase of the plant, you can make an exchange. You must have a sales receipt with you, which indicates the name of the seedling and a container with a barcode.',
                ],
            },
            policy: {
                head: "User agreement",
                block1: {
                    head: "TERMS",
                    list: [
                        "In accordance with Article 633 of the Civil Code of Ukraine, this contract is an official offer of Party 1 - INDIVIDUAL ENTREPRENEUR DMYTRIEVA LILYANA VIACHESLAVIVNA through the Internet store http://brusvyana.com.ua/ (hereinafter - the Seller) to enter into a contract for the sale of goods and/or a contract on the provision of services on the terms set forth in this Offer.",
                        "This offer is a public contract-offer (hereinafter the Contract) and has the corresponding legal force, for the sale of goods to an unlimited number of persons (hereinafter the Buyer) and the provision of Services to an unlimited number of persons, which are presented in the relevant section of the Seller`s Website.",
                        "The provisions of this Agreement regulate the relationship between the Seller, on the one hand, and the Buyer, on the other hand (when referred to jointly, they are referred to as the Parties) based on the terms of this Agreement.",
                        'All users of the Seller`s Website can access the Agreement (offer) at any time by clicking on the "Public Offer" link on the Seller`s Website.',
                        "With this Agreement, the Parties confirm the achievement of full agreement between them regarding the rules, terms, restrictions and other conditions of cooperation set forth in this Agreement.",
                        "This agreement, from the moment of its conclusion, terminates guarantees, pacts and any other agreements (in written and verbal form) between the Parties and is binding on the Parties.",
                        "By concluding the Agreement (that is, by accepting the terms of the actual Offer (by placing an Order), the Buyer confirms the following:",
                        [
                            "The buyer is fully and completely acquainted with and agrees with the terms of this offer (offer);",
                            "The information provided by the Buyer when placing an order for Goods or Services is correct and up-to-date;",
                            "The buyer gives permission for the collection, processing and transfer of personal data for the purpose of fulfilling the terms of this Agreement, the possibility of mutual settlements, as well as for receiving invoices, acts and other documents. The permission to process personal data is valid for the entire term of the Agreement.",
                        ],
                    ],
                },
                block2: {
                    head: "DEFINITIONS AND TERMS.",
                    list: [
                        "In this offer, unless the context otherwise requires, the following terms have the following meanings:",
                        [
                            '"Offer" - a public offer of the Seller, addressed to any natural or legal person, with the aim of concluding a contract for the purchase and sale of the Goods and/or a contract for the provision of services under the existing conditions specified in the Contract.',
                            '"Product" - a list of names of the assortment, regarding the purchase of which the Seller`s offer is posted on the Website http://brusvyana.com.ua/, but not limited to the site.',
                            '"Services" - a list of actions performed by the Seller at the expense of the Buyer from the list of services available for ordering by the Buyer on the Website http://brusvyana.com.ua/.',
                            '"Seller" is a company that is the owner or distributor of the Goods and sells such Goods and provides relevant Services using the Website http://brusvyana.com.ua/.',
                            '"Buyer" - a natural or legal entity that has entered into contractual relations with the Seller on the terms specified in this Agreement.',
                            '"Acceptance" is the complete and unquestionable acceptance by the Buyer of the terms of the Agreement.',
                            '"Website" is an automated information system, available on the Internet at the network address: http://brusvyana.com.ua/, as well as all "subdomains" that refer to this address and are used by the Seller under the right of ownership.',
                            '"Order" - the Buyer`s application for the purchase of Goods and/or the order of Services addressed to the Seller, properly executed and posted on the Seller`s Website.',
                            '"Courier delivery" - direct transfer of the Goods from a delivery service employee, or from an employee of the Seller, or from another person authorized by the Seller to the Buyer at the place indicated by the Buyer as the delivery address, on paid or free terms.',
                            '"Payment system" - a bank/participant of the payment system/operator of payment infrastructure services, which, on a contractual basis with the Seller, ensures the acceptance and transfer of payments using the details of the Buyers` payment cards for the Goods and/or Services offered on the Seller`s Website.',
                            "A personal account is an information and telecommunications system that is part of the Website, with the help of which the User has the opportunity to order Goods and/or Services, change the terms of the provision of Services, and pay for the Goods and/or Services in accordance with the procedure provided for in this Agreement. Information about the Buyer and the Goods and/or Services ordered by him is stored in his personal account.",
                        ],
                    ],
                },
                block3: {
                    head: "SCOPE OF THE CONTRACT.",
                    list: [
                        "Under this contract, the Seller undertakes to transfer to the Buyer the ownership of the Goods selected by the Buyer on the relevant page of the Website and/or to provide the Services selected by the Buyer on the relevant page of the Website, and the Buyer undertakes to pay for and receive such Goods and/or Services on the terms of this Agreement.",
                        "The Seller has the right to involve third parties to fulfill the Buyer`s order.",
                        "The seller confirms that he has all the necessary permits to carry out business activities that regulate the scope of legal relations that arise and operate in the process of executing this Agreement, and also guarantees that he has the right to manufacture and/or sell the goods, and the right to provide relevant services without any restrictions in accordance with the requirements of the current legislation of Ukraine, and undertakes to bear responsibility in case of violation of the Buyer`s rights during the execution of this Agreement.",
                        "Information about the Goods and/or Services displayed on the Website may contain incomplete information, accurate information can be obtained by the Buyer by clarifying the conditions with the relevant manager of the Seller at the telephone numbers indicated in the relevant section of the Website.",
                    ],
                },
                block4: {
                    head: "MOMENT OF CONTRACT CONCLUSION",
                    list: [
                        "The moment of conclusion of this Agreement is the moment of ordering the Goods and/or Services by the Buyer, regardless of the method of Ordering.",
                        "The Buyer is obliged to familiarize himself with the terms of the Agreement, the Seller is not obliged to inform the Buyer about the existence of the Agreement.",
                        "If the Buyer orders the Goods and/or Services by phone, in the Seller`s store, by e-mail or directly through the Seller`s Website, the Buyer confirms that he has read this Agreement and agrees with it.",
                        'By pressing the "Confirm order" button, the buyer undertakes to pay the cost of such Goods and/or Services.',
                        "This Agreement is concluded by accepting the terms of this Agreement. The absence of a copy of the Agreement signed between the parties on a paper medium, with signatures and seals of the parties, in the event of actual payment by the Buyer, is not a reason to consider this Agreement not concluded.",
                        "The Seller executes the Contract on paper only if the Buyer requests it in writing.",
                    ],
                },
                block5: {
                    head: "ORDER PROCEDURE BY THE BUYER.",
                    list: [
                        'The Buyer independently places the Order on the corresponding page of the Website http://brusvyana.com.ua/ by adding Goods and/or Services to the Virtual Cart by clicking the "Buy" button.',
                        'After adding all the desired Goods and/or Services to the virtual cart, the Buyer places the Order independently by pressing the "Place Order" button and filling out the appropriate form on the website http://brusvyana.com.ua/, entering contact data in the mandatory before filling out the field, entering the data necessary for payment and delivery of the Goods and/or provision of the Services selected by the Buyer.',
                        'Completion of registration and sending of the Order to the Seller is carried out after filling in all the necessary fields in the Site form by clicking on the button "Make an Order".',
                        "It is possible to make changes to the completed Order within 60 minutes from the moment of the final completion of the Order by contacting the Seller at the phone number indicated on the Website with the order number and your contact information.",
                        "It is also possible to place an order by calling the phone specified in the appropriate section of the Website. In this case, the Buyer must provide the exact name of the selected Product and/or Service, brand, article number, or other data that allows for a clear and unambiguous identification of the Product and/or Service, and provide the Seller with all necessary contact information about delivery and payment.",
                        "After receiving the Order, the Buyer checks the availability of the Goods selected by the Buyer and/or the possibility of providing the Service selected by the Buyer. If it turns out that one or more Goods for one or another reason are not available from the Seller on the day of the Order, or it turns out that the Seller will not be able to provide the Service chosen by the Buyer for one or another reason, the Seller contacts the Buyer using the contact number to coordinate the Order - informs about the missing Goods and the reasons for the impossibility of providing the corresponding Services, and offers similar or similar Goods and/or Services. The Buyer must agree on the Order taking into account such notification from the Seller immediately, or by the end of the day on which the Order was placed, or on the next working day of the Seller (in the case of placing the Order outside the Seller`s working hours).",
                        "Orders are processed by the Seller according to the following schedule: from Monday to Friday from 8:00 a.m. to 4:30 p.m.",
                    ],
                },
                block6: {
                    head: "COST AND PAYMENT PROCEDURE OF THE ORDER",
                    list: [
                        "Prices for Goods and Services are indicated in the relevant section of the Seller`s Website. All prices for Goods and Services are displayed in the national currency of Ukraine (hryvnia), including taxes.",
                        "The prices for the Goods and Services specified on the Seller`s Website are indicative in nature and do not include the cost of delivery, commission and additional fees of banks and payment systems. The buyer pays such fees and shipping costs independently and at his own expense. The size of the commission or additional fee is reported by the relevant bank and payment system.",
                        "The price of Goods and Services on the Website may be changed by the Seller unilaterally. At the same time, the price of the Goods and/or Services ordered by the Buyer cannot be changed.",
                        "In case of incorrect indication of the price of the Goods and/or Services ordered by the Buyer, the Seller informs the Buyer about this to confirm the Order at the corrected price or cancel the Order. If it is impossible to contact the Buyer, this Order is considered cancelled. If the Order has been paid, the Seller returns the amount paid for the Order to the Buyer`s payment card.",
                        "The total amount of the Order is the price of the Goods and/or Services and the cost of delivery.",
                        "The list of Goods and/or Services of the confirmed Order, as well as the total price of the Order, cannot be changed after the confirmation of the Order by the Seller, except for the cases stipulated by the current legislation of Ukraine.",
                        "The Buyer pays for the order by any method chosen when ordering Goods and/or Services in the relevant section of the Seller`s Website.",
                        "The Buyer can pay for the Order in the following ways:",
                        [
                            "In cash at the time of delivery of the Goods by courier and/or after providing the selected Service;",
                            "In cash or non-cash upon receiving the goods in the Seller`s store;",
                            "Cash on delivery upon receipt of the Order at the representative office of the delivery service on the territory of Ukraine;",
                            "Cashless, using a Visa or Mastercard payment card directly on the Seller`s Website.",
                            "When paying for the Order online, the User is redirected to the LiqPay payment service, where he can pay for the Order using Visa and Mastercard payment system cards.",
                        ],
                        "Payment is credited only if the order published on the Seller`s Website is followed.",
                        "In the event of non-payment, incomplete payment or late payment, the Seller reserves the right not to provide the Goods and/or Services, suspend or completely fail to fulfill the obligations assumed and at the same time is not responsible for the possible consequences of such decision/actions.",
                        "Goods and Services are guaranteed and provided only upon receipt of payment by the Seller. Until the payment is received, the Seller has no obligations to the Buyer regarding the Goods and/or Services ordered by the Buyer",
                        "In the case of non-cash payment, the Buyer`s obligation to pay the value of the Order is considered fulfilled from the moment the relevant funds are credited to the Seller`s current account.",
                        "In the case of non-cash payment, the Buyer must present to the manager or courier a document confirming the fact of payment for the Goods and/or Services (payment order, payment receipt, etc.).",
                        "The seller is not responsible for the procedure of transferring / crediting funds.",
                    ],
                },
                block7: {
                    head: "TERMS OF RETURN OF GOODS AND MONEY.",
                    list: [
                        "Exchange or return of Goods of proper quality by the Seller is not carried out except in the cases specified in this Agreement.",
                        "The seller allows exchange or return in the following cases:",
                        "In the event of the death of plants, in a period not exceeding 1 year from the moment of their purchase, and provided that the fact of the death of such a plant is excluded due to the lack of proper care on the part of the Buyer (violation of the rules for watering a specific variety/type of plants). The establishment of the absence of the fact of improper care of the plant, violation of the watering rules of a specific variety/species of plants is carried out by the Seller`s expert directly in the Seller`s store. Establishing the absence of the fact of improper care of plants, the total value of which exceeds UAH 50,000. carried out by the Seller`s expert at the place of planting of such plants.",
                        'The return of goods of inadequate quality is carried out in accordance with the Law of Ukraine "On the Protection of Consumer Rights", in case of defects.',
                        "If defects are discovered, the Buyer is obliged to record them in a written act of arbitrary form. The act must be signed by the Buyer and the person who delivered the Goods or the Seller.",
                        "In the case of returning the Goods, the Buyer must return the Goods by the courier/postal company that delivered the Order to the address of the store from which the Order was sent or by self-delivery of such goods to the relevant Seller`s store.",
                        "The return of the Goods to the Seller`s address is carried out at the expense of the Buyer and the Seller does not reimburse the Buyer.",
                        "Reimbursement of funds to the Buyer for goods of inadequate quality is carried out on the condition that he provides the following documents:",
                        "Applications for the return of goods/funds;",
                        "Act of identified deficiencies;",
                        "Documents confirming payment (purchase) of goods.",
                        "The application for the return of goods/funds must contain the name of the goods to be returned, the amount equal to its value, the current account in the national currency of the Buyer (in the case of paying for the goods by non-cash means or using payment systems). The cost of delivery of the order is non-refundable.",
                        "Refunds to the Buyer are made on the condition that all necessary documents for the refund are duly filled out in accordance with the requirements specified in clauses 7.7, 7.8. of this Agreement and provided to the Seller.",
                        "In the case of returning goods, a necessary condition for refunding money for the returned goods is the provision by the Buyer to the Seller of documents confirming the payment (purchase) of the returned Goods.",
                        "The refund of the amount paid for the Goods is carried out in the same way as the payment was made, or in another way agreed by the Parties.",
                        "In case of receipt of the goods at the branch of the Courier Company, the refund for the returned goods is possible only by non-cash settlement, namely: from the Seller`s current account to the Buyer`s current account in the national currency of Ukraine.",
                        "Refunds in cash are not possible, unless payment of the price of the Goods was made in cash in the store.",
                        "A claim (application) for the return of the amount of money paid for goods of inadequate quality must be satisfied within 7 working days from the date of submission of the corresponding claim (application).",
                    ],
                },
                block8: {
                    head: "TERMS OF DELIVERY OF GOODS.",
                    list: [
                        "The terms of delivery and payment of the Goods are indicated on the relevant sections of the Seller`s Website. All questions that arose during the payment and delivery of the Goods can be clarified by the Buyer using the contact information in the Contacts section.",
                        "The Buyer has the right to receive the ordered Goods by means of self-pickup (self-pickup) from the Seller`s store or to use the services of courier or postal delivery.",
                        "Self-pickup is carried out by the Buyer from the Seller`s store, which is located at the address Zhytomyr region, Brusylivskyi district, Kostivtsi village, Poleva street 1, as well as from another place agreed by the parties.",
                        "Courier delivery of the order to the Buyer is carried out upon agreement with the Buyer and to the address specified by him.",
                        "Courier delivery of the ordered Goods is carried out within Boryspil district and Kyiv region.",
                        "The delivery of goods can be carried out by the Seller`s employees or with the involvement of third parties (the carrier).",
                        "The fact of receiving the goods and the absence of claims to the quality of the goods is confirmed by the Buyer with his own signature upon receipt of the goods.",
                        "The seller is not responsible for the delivery time of the order, if the delivery is agreed and depends on the actions of third parties (carriers). The Seller, at the request of the Buyer, can give the Buyer an approximate cost of delivery for informational purposes only.",
                        "The Buyer understands and agrees that: delivery is a separate service that is not an integral part of the Goods purchased by the Buyer, the performance of which ends when the Recipient receives the Goods and makes payment for them.",
                        "When accepting the Order from the courier, the Buyer is obliged to inspect the delivered Goods and check it for compliance with the declared quality, assortment and completeness of the Goods, as well as the integrity of the packaging. In the absence of claims to the delivered Goods, the Buyer pays for the Order.",
                        "The risk of accidental damage to the Goods passes to the Buyer from the moment the Order is handed over to him and the recipient of the Order signs the documents confirming the delivery of the Order.",
                        "In the case of delivery of the Goods using the Nova Poshta delivery service, the signature in the declaration provided by the service indicates that the recipient has not filed any claims against the Goods and the Seller has fully and properly fulfilled his obligation to deliver the Goods.",
                        "When transferring the Goods, the Seller or representative of the courier service provides the Buyer with an accompanying document (bill of lading, check) in accordance with the Law of Ukraine.",
                    ],
                },
                block9: {
                    head: "RIGHTS AND OBLIGATIONS OF THE SELLER.",
                    list: [
                        "The seller is obliged to:",
                        "Ensure the sale of Goods and/or the provision of Services in accordance with this Agreement and the requirements of the current legislation of Ukraine;",
                        "Deliver to the Buyer Goods of appropriate quality and/or provide quality Services in accordance with the terms of this Agreement and the Buyer`s Order;",
                        "Check the qualitative and quantitative characteristics of the Goods during its packaging and shipment to the Buyer;",
                        "Carry out personally or arrange the delivery of the Goods to the Buyer, if the Parties have agreed on the delivery of the Goods;",
                        "Provide the Buyer with all necessary information in accordance with the requirements of current legislation and this Offer;",
                        "From the moment of concluding this Agreement, to fully ensure all obligations to the Buyer in accordance with the terms of this Agreement;",
                        "Notify Buyers of changes to this Agreement by posting relevant changes on the Website;",
                        'To ensure the fulfillment of obligations in accordance with the requirements of the Law of Ukraine "On the Protection of Consumer Rights";',
                        "Ensure the confidentiality of the Buyer`s data and compliance with the legislation of Ukraine on the protection of personal data;",
                        "Post on the Website up-to-date information about the Goods and Services and their cost;",
                        "In case of delivery of goods of inadequate quality, replace such goods with goods of appropriate quality, or return the paid funds;",
                        "Perform other duties in accordance with current legislation and this Agreement;",
                        "The seller has the right to:",
                        "Demand payment for the transferred Goods and/or the provided Service in the manner and on the terms stipulated in the Agreement;",
                        "Unilaterally terminate this Agreement in the event of a breach by the Buyer of the terms of this Agreement;",
                        "Refuse to fulfill the Order in case of force majeure;",
                        "Change this Agreement unilaterally prior to its conclusion by posting the changes on the Website;",
                        "Transfer your rights and obligations for the execution of Orders to third parties;",
                        "To independently and unilaterally change the prices of the Goods (including those ordered by the Buyer) and the range of Goods and/or prices of Services (including those ordered by the Buyer).",
                        "Unilaterally change the Order, including completely or partially refuse to fulfill the Order, in the event of the absence of the Product or the reason for the impossibility of providing the relevant Services on the date of receipt of the Order by the Buyer;",
                        "Refuse to perform the concluded Agreement in case of discovery of the fact that the Buyer has provided inaccurate information; the Buyer`s failure to pay for the goods and/or Services, the Buyer`s failure to receive the Goods at the agreed place and time, and/or the admission of other dishonest actions that may result in complications for the Seller or the impossibility of fulfilling this Agreement.",
                    ],
                },
                block10: {
                    head: "RIGHTS AND OBLIGATIONS OF THE BUYER.",
                    list: [
                        "The buyer is obliged to:",
                        "Before visiting the online store http://brusvyana.com.ua/, familiarize yourself with the content of the Contract-offer, terms of payment and delivery on the store`s website.",
                        "Provide the Seller with reliable information necessary for the proper execution of the Agreement;",
                        "Timely pay and receive the Order under the terms of this Agreement;",
                        "Familiarize yourself with information about the Product and/or Service posted on the Seller`s Website;",
                        "In order for the Seller to fulfill his obligations to the Buyer, the latter must provide all the necessary data that uniquely identify him as the Buyer and are sufficient to deliver the Goods he paid for to the Buyer or provide the Buyer with the Service he paid for;",
                        "When receiving the Goods from the person who delivered them, verify the integrity and completeness of the Goods by inspecting the contents of the packaging. In the case of detection of damage or incompleteness of the Goods - record them in the act, which must be signed by the person who delivered it to the Buyer together with the Buyer;",
                        "Use the rights granted to him in good faith, comply with the terms of the Agreement and current legislation;",
                        "Perform other duties provided for by this Agreement and current legislation of Ukraine.",
                        "The buyer has the right to:",
                        "Place an order on the relevant page of the Seller`s Website;",
                        "To require the Seller to fulfill the terms of this Agreement;",
                        "Require the Seller to provide all necessary information in accordance with the requirements of current legislation and this Offer;",
                        "Refuse to accept the Product in case of inconsistency of its characteristics with the previously ordered product;",
                        "Demand from the Seller the replacement of Goods of inadequate quality with Goods of suitable quality or the return of paid funds.",
                        "To exercise other rights stipulated by this Agreement and the current legislation of Ukraine.",
                    ],
                },
                block11: {
                    head: "RESPONSIBILITIES OF THE PARTIES.",
                    list: [
                        "The Seller is not responsible for the use or further use of the Goods purchased by the Buyer using, but not limited to, the Seller`s Website.",
                        "The buyer, when placing an order, is responsible for the accuracy of the information provided about himself, and also confirms that he has read and agrees with the terms of this Agreement.",
                        "The Buyer alone bears full responsibility for providing false or false information, which made it impossible for the Seller to properly fulfill its obligations to the Buyer.",
                        "In case of non-payment, incomplete or late payment for the Goods and/or Services, the Seller has the right to terminate the execution of the Order and notify the Buyer of the non-payment, incomplete or late payment of the Order.",
                        "The buyer is solely responsible for the correctness and timeliness of the payments made by him. Amounts mistakenly credited by the Buyer to the accounts of third parties are not compensated or reimbursed by the Seller.",
                        "The Seller is not responsible for the losses incurred by the Buyer as a result of the refusal of the Goods and/or Services in accordance with the requirements of this Agreement.",
                        "The Seller is not responsible for the integrity and preservation of the Goods, if the delivery of the Goods is not carried out by the Seller`s employees.",
                        "The Seller is not responsible for the methods and results of the Buyer`s use of the information received during the execution of the Order by the Seller.",
                        "The Seller shall not be liable to any party for any direct, indirect, special or other consequential damages resulting from any use of the information on the Seller`s Website or any other site linked from the Website .",
                        "The Seller is released from responsibility for incomplete or improper fulfillment of the Order under the Contract in cases of non-fulfillment by the Buyer of any of the terms of the Contract.",
                        "In case of non-receipt of the Order by the Buyer, if such non-receipt occurred due to the fault of the Buyer, the Seller has the right to use such Goods at his own discretion and is relieved of the obligation to transfer such Goods to the Buyer.",
                        "In the event that the Buyer violates the terms of this Agreement, the Seller has the right to terminate the Order until the time of elimination of the violations committed by the Buyer and compensation (compensation) for the losses caused to the Seller by such violation and / or to terminate the Agreement by sending an appropriate notice to the Buyer.",
                        "The total liability of the Seller under the Contract, for any lawsuit or claim, including from the Buyer`s side regarding the performance of the Contract, is limited to the total value of the Buyer`s Order.",
                        "he seller does not provide guarantees for uninterrupted operation of the Website and compliance with any deadlines for restoring the functionality of the Website in the event of work interruptions due to failures in telecommunication and energy networks.",
                        "The parties bear other responsibility for non-fulfillment or improper fulfillment of the terms of this Agreement in the manner provided for by this Agreement and the current legislation of Ukraine.",
                    ],
                },
                block12: {
                    head: "FORCE MAJEURE CIRCUMSTANCES.",
                    list: [
                        "The parties are released from responsibility for non-fulfillment or improper fulfillment of obligations under the terms of the Agreement during force majeure. Force majeure means extraordinary and irresistible circumstances under the given conditions that prevent the Parties from fulfilling their obligations under this Agreement. These include natural phenomena (earthquakes, floods, etc.), circumstances of public life (military operations, states of emergency, major strikes, epidemics, etc.), prohibitive measures of state bodies (transport bans, currency restrictions, international sanctions , bans on trade) and others.",
                        "In the event of the occurrence of the circumstances specified in Clause 12.1. of this Agreement, fulfillment of obligations under this Agreement is postponed for the duration of such force majeure circumstances and their consequences.",
                        "For the duration of the circumstances specified in clause 12.1. of this Agreement, the parties have no mutual claims against each other and each of the parties assumes its own risk as a result of force majeure circumstances.",
                        "If, due to force majeure, non-fulfillment of obligations under this Agreement continues for more than three months, each of the Parties has the right to unilaterally terminate this Agreement by notifying the other party in writing. Despite the occurrence of force majeure, before terminating this Agreement as a result of force majeure, the Parties shall make final mutual settlements.",
                        "The occurrence of force majeure circumstances is not a reason for the Buyer`s refusal to pay for the Goods that were delivered to him before the occurrence of force majeure circumstances.",
                    ],
                },
                block13: {
                    head: "CONFIDENTIALITY",
                    list: [
                        'By accepting this offer, the Buyer gives his consent to the collection and processing of his personal data, in accordance with the Law of Ukraine dated June 1, 2010 No. 2297-VI "On the Protection of Personal Data". The Buyer`s personal data is processed in accordance with the Law of Ukraine "On the Protection of Personal Data".',
                        "Data provided by the Buyer when placing an Order through the Seller`s Website will be used to process Orders for the purchase of Goods and/or Services, receive information about the order, and send it by means of telecommunications (e-mail, mobile phone). advertising offers, information about promotions, sweepstakes, special offers or any other information about the activities of the Seller or its partners, for other commercial purposes.",
                        "The owner of the personal data is a natural person entrepreneur Dmitrieva Liliana Vyacheslavivna, EDRPOU code 2707901127, location: 12613, Zhytomyr region, Brusylivskyi district, Kostivtsi village, Poleva street 1.",
                        "The Seller guarantees the confidentiality and protection of personal data of Buyers.",
                        "The buyer gives the right to the seller to process his personal data, including: to include personal data in the database (without additional notification about this), to carry out indefinite data storage, their accumulation, update, change (if necessary).",
                        "The Seller undertakes to ensure the protection of data from unauthorized access by third parties, not to distribute or transfer data to a third party (except for the transfer of data to commercial partners, persons authorized by the Seller to carry out direct data processing for the specified purposes, including mobile operators, postal or courier delivery services, contact centers, advertising agencies, as well as at the request of competent state authorities in accordance with the procedure established by law).",
                        "Only persons directly involved in the execution of the Order have access to the Buyer`s personal data.",
                        "The Seller undertakes to: observe confidentiality regarding the personal data of Buyers; prevent unauthorized use of Buyers` personal data by third parties; to exclude access to personal data of Buyers, persons who are not directly related to the execution of the Order, except for cases provided for by the current legislation of Ukraine.",
                        'The Buyer has the right to refuse to receive advertising and other information by means of a message to the e-mail specified in the "Contacts" section or elsewhere on the Seller`s Website.',
                        "The Buyer has the right to request information about whether his data is being processed by the Seller. The seller provides such an answer within 30 calendar days.",
                        "Personal data collected in accordance with this contract are deleted as soon as they are no longer necessary to fulfill the purpose of their collection.",
                        "The Buyer has the right to request the deletion and access to personal data processed/stored by the Seller.",
                        'The request for deletion or access to personal data must be made by the Buyer by sending a letter to the e-mail address specified in the "Contact" section or elsewhere on the Seller`s Website, or by sending a letter to the Seller`s location.',
                        "The Buyer`s request to delete personal data will be considered by the Seller as the Buyer`s request to terminate this Agreement and refuse further receipt of the Seller`s Goods and/or Services.",
                        "The Seller has the right to make recordings of telephone conversations with the Buyer, having previously informed the Buyer about such a recording. At the same time, the Seller undertakes: to prevent attempts of unauthorized access to information obtained during telephone conversations and / or its transfer to third parties who are not directly related to the execution of Orders.",
                    ],
                },
                block14: {
                    head: "MISCELLANEOUS.",
                    list: [
                        "This Agreement enters into force from the moment of acceptance of the offer by the Buyer and is valid until the Parties fully fulfill their obligations",
                        "The contract may be terminated in accordance with the procedure provided for by the current legislation of Ukraine",
                        'The actual date of conclusion of this Agreement between the parties is the date of acceptance of the terms of this Agreement by the Buyer, in accordance with Art. 11 of the Law of Ukraine "On Electronic Commerce"',
                        "According to Article 633 of the Civil Code of Ukraine, this contract is a public offer (public contract), and in case of acceptance of its conditions (acceptance), the Buyer undertakes to faithfully fulfill them.",
                        "All disputes and disagreements that have arisen regarding the fulfillment of the terms of this Agreement shall be resolved through negotiations on the basis of a written statement (claim) of the Buyer.",
                        "After receiving a statement (claim) from the Buyer, the Seller is obliged to satisfy the stated requirements or send a reasoned refusal to the Buyer within 20 (twenty) calendar days.",
                        "In the event that the dispute that has arisen is not settled in a claim procedure, the Buyer has the right to apply to the court at the Seller`s location.",
                        "The use of the resource of the Seller`s Website for a preview of the product, as well as for placing an order for the Buyer, is free of charge.",
                        "All notices and other documents under the Agreement must be sent to the addresses specified in this Agreement and/or specified in the relevant section of the Seller`s Website.",
                        "All text information and graphic images on the Website are the property of the Seller.",
                        "The Seller`s website and the services provided may be temporarily partially or completely unavailable due to preventive or other work, or for any other technical reasons. The Seller`s technical service has the right to periodically carry out necessary preventive or other work with or without prior notice to the Buyers.",
                        "The seller has the right to transfer his rights and obligations for the execution of Orders to third parties.",
                        "The buyer guarantees that all the terms of this Agreement are clear to him, and he accepts them unconditionally and in full.",
                        "All issues not regulated by this Agreement are regulated by the current legislation of Ukraine.",
                    ],
                },
                reqi: {
                    head: "DETAILS OF THE SELLER.",
                    fop: "Dmitrieva Liliana Vyacheslavivna, entrepreneur",
                    ipn: "TIN - 2707901127",
                    iban: 'iban UA123052990000026009016402964 JSC CB "PRIVATBANK"',
                },
            },
        },
        contacts: {
            head: "Contact details",
            smalPhone: "Retail chains / small wholesale",
            wholesale: "Wholesale",
            retail: "Retail",
            address:
                "Zhytomyr region, Brusilovsky district, with. Kostovtsy, st. Field 1",
        },
    },
};

//Contacts info
const ContactsInfo = {
    mail: "brusvyana@ukr.net",
    mailRetail: "brusvyanagarden@gmail.com",
    smallPhone: "tel:0674116908",
    smallPhoneLabel: "+38 067 411 69 08",
    bigphoneLabel: "+38 067 411 36 91",
    bigPhone: "tel:380674113691",
    phoneLabel: "+380 67 411 69 06",
    phonePost: "tel:380674113688",
    phonePostLabel: "+380 67 411 36 88",
    phone: "tel:380674116906",
    facebook: "https://www.facebook.com/profile.php?id=100063732266725",
    facebook2: "https://www.facebook.com/LilianaDmitrieva.ua",
    instagram: "https://www.instagram.com/brusvyana_garden/",
    instagram2: "https://www.instagram.com/brusvyana/",
};

// Partners info
const partners = [
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению. ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению. ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению. ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению. ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },

    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },

    {
        name: 'ООО "Компания Профиль"',
        logo: "1.png",
        link: "https://www.google.com/",
        description:
            'ООО "Компания Профиль" - одна из крупнейших компаний в Ua, основанная в 1992 году. Компания предлагает своим клиентам профессиональные услуги по профессиональному профилированию и профессиональному обучению.',
        cupon: "Brusvyana01",
    },
];

export { partners, MainTranslate, ContactsInfo };
